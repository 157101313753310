import {takeEvery,put} from 'redux-saga/effects'
import {getCartInfo, deleteCart,addToCart} from '../../api'
import {types} from '../modules/cart'
import Immutable from 'immutable'
//获取购物车数据
function* fetchData(action) {
    const cartData = yield getCartInfo(action.data);
    yield put({
        type: types.GET_DATA,
        data: cartData
    })
}

//根据cartID删除购物车记录
function* deleteCartRecord(action) {
    const res = yield deleteCart({cartID:action.cartId})
    if(res.status === 1) {
        alert('删除成功')
        yield put({
            type: types.DELETE_CART_RECORD,
            cartId: action.cartId
        })
    }
}

// 修改当前选中的尺码数量
function* changeSpecNumber(action) {
    // console.log(action.data)
    const {
        cartId,
        number,
        specIndex,
        data:{ProductID,UserDesign,SpecData}
    } = action
    let newSpecData = Immutable.fromJS(SpecData).setIn([0,'spec',specIndex,'num'],number)
    // console.log(newSpecData.toJS())
    let addCartData = {
        ProductID: ProductID,
        JoinType: 'personal',
        BuyNow: 0,
        CartID: cartId,
        UserDesignID: UserDesign,
        SpecData: JSON.stringify(newSpecData.toJS())
    }
    const res = yield addToCart(addCartData)
    if(res.status === 1) {
        yield put({
            type: types.CHANGE_SPEC_NUMBER,
            cartId,
            number,
            specIndex,
        })
    }
}


export default function* index() {
    yield takeEvery(types.GET_DATA_REQUESTED,fetchData)
    yield takeEvery(types.DELETE_CART_RECORD_REQUEST,deleteCartRecord)
    yield takeEvery(types.CHANGE_SPEC_NUMBER_REQUEST,changeSpecNumber)
}