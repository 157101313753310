import React, { Component } from 'react';
import styles from './index.module.less'
import {
    Navigation,
    SlideImage,
    ThemeArea,
    Footer,
    Favorite,
    Toolbar
} from '../../components'
import { Route } from 'react-router-dom';

class Home extends Component {
  
    render() {
        return (
            <div className={styles.home}>
               <Navigation />
                <SlideImage />
                {/* <ThemeArea /> */}
                <Footer showTech={true} />
                <Toolbar />
                                      
            </div>
        );
    }
}

export default Home;