import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'
import {GoodsItem} from '../../../index'

class ContentArea extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,  //猜你喜欢数据
        index: PropTypes.number.isRequired, //商品栏的位移
    }
    render() {
        const {
            data,
            index
        } = this.props;
        return (
            <div className={styles.contentArea}>
                <div className={styles.content} style={{width: `${data.length * 280}px`, left: -index * 1120}}>
                    {
                        data.length >0 && data.map( item => 
                            <GoodsItem 
                                key={item.ProductID}
                                data={item}
                            />    
                        )
                    }
                </div>
            </div>
        );
    }
}

export default ContentArea;