import React, { Component } from 'react';

import styles from './index.module.less'


class Loading extends Component {
    render() {
        return (
         <div className={styles.body}>
            <div className={styles.loading}>
                <div><span></span></div>
                <div><span></span></div>
                <div><span></span></div>
            </div>
         </div>
        );
    }
}

export default Loading;