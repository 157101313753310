import React, { Component } from 'react';
import styles from './style.module.less'
import MenuItem from './MenuItem';

const menuData = [
    {
        title: '账号管理',
        children: [
            // {
            //     title: '个人中心',
            //     path: '/personalCenter'
            // },
            {
                title: '账户信息',
                path: '/accountInfo'
            },
            {
                title: '消息通知',
                path: '/message'
            },
            {
                title: '地址管理',
                path: '/address'
            },
            {
                title: '我的积分',
                path: '/myScore'
            },
            {
                title: '我的收藏',
                path: '/myCollection'
            },
            {
                title: '我的作品',
                path: '/myDesign'
            },
            {
                title: '我的体征',
                path: '/bodyInfo'
            },
        ]
    },
    {
        title: '交易管理',
        children: [
            {
                title: '订单管理',
                path: '/orderList'
            },
            {
                title: '优惠券',
                path: '/coupon'
            },
        ]
    }
]

class ShopCenterMenu extends Component {
    render() {
        return (
            <div className={styles.body}>
                {
                    menuData.map( item => 
                        <MenuItem 
                            data={item}
                            key={item.title}
                        />    
                    )
                }
            </div>
        );
    }
}

export default ShopCenterMenu;