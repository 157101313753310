import React, { Component } from 'react';
import styles from './style.module.less'

class Title extends Component {
    render() {
        return (
            <div className={styles.body}>
                <h3>猜你喜欢</h3>
            </div>
        );
    }
}

export default Title;