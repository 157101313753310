import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'
import Top from './Top';
import Bottom from './Bottom';

class CouponItem extends Component {
    static propTypes = {
        data: PropTypes.object,  //单个优惠券的信息
        currentId: PropTypes.number, //当前选中的优惠券类别，1表示可使用，2表示已使用，3表示未使用
    }
    render() {
        return (
            <div className={styles.body}>
                <Top {...this.props} />
                <Bottom {...this.props}  />
            </div>
        );
    }
}

export default CouponItem;