import React, { Component } from 'react';
import styles from './style.module.less'
import location from './location.min.json'
import ShowAddressAreaItem from './ShowAddressAreaItem';
import PropTypes from 'prop-types'

// console.log(location)
// console.log(Object.values(location))
class ShowAddressArea extends Component {
    static propTypes = {
        chooseTab: PropTypes.number, //当前行政级别
        setShowAddressArea: PropTypes.func, //设置显示地址选择界面的方法
        setProvinceCode: PropTypes.func, //设置省级行政区域代码的方法
        setCityCode: PropTypes.func, //设置市级行政区域代码的方法
        setDistrictCode: PropTypes.func, //设置区级行政区域代码的方法
        setChooseTab: PropTypes.func, //设置更换当前行政级别的方法
    }
    render() {
        const {
       
            chooseTab,
            setChooseTab,
            formData
        } = this.props
        // console.log(formData)
        return (
            <div className={styles.showAddressArea}>
                <div className={styles.addressTab}>
                    <div className={chooseTab === 1 ? styles.provinceTab : styles.cityTab} onClick={setChooseTab.bind(this,1)}  >
                        <span className={styles.title}>{formData.get('provinceCode') ? location[formData.get('provinceCode')].name : '请选择'}</span>
                        <span className={`iconfont icon-arrow-up1 ${styles.icon}`}></span>
                    </div>
                    {
                        formData.get('provinceCode')
                        ?
                        <div className={chooseTab === 2 ? styles.provinceTab : styles.cityTab} onClick={setChooseTab.bind(this,2)}>
                            <span className={styles.title}>{formData.get('cityCode') ? location[formData.get('provinceCode')]['cities'][formData.get('cityCode')].name : '请选择'}</span>
                            <span className={`iconfont icon-arrow-up1 ${styles.icon}`}></span>
                        </div>
                        :
                        ''
                    }
                    {
                        formData.get('cityCode')
                        ?
                        <div className={chooseTab === 3 ? styles.provinceTab : styles.cityTab} onClick={setChooseTab.bind(this,3)}>
                            <span className={styles.title}>{formData.get('districtCode') ? location[formData.get('provinceCode')]['cities'][formData.get('cityCode')]['districts'][formData.get('districtCode')] : '请选择'}</span>
                            <span className={`iconfont icon-arrow-up1 ${styles.icon}`}></span>
                        </div>
                        :
                        ''
                    }
                </div>
                <div className={styles.addressArea}>
                    <ul className={styles.ulStyle}>
                        {this.renderAddressArea()}
                    </ul>
                </div>
            </div>
        );
    }

  

    //渲染区域选择界面
    renderAddressArea = () => {
        const {
            chooseTab,
            setProvinceCode,
            setCityCode,
            setDistrictCode,
            formData
        } = this.props
        switch(chooseTab) {
            case 1:
                return   Object.values(location).map( item => 
                    <ShowAddressAreaItem
                        key={item.code}
                        name={item.name}
                        clickFn={setProvinceCode.bind(this,item.code,item.name)}
                    />
                 )
            case 2: 
                return Object.values(location[formData.get('provinceCode')].cities).map( item => 
                    <ShowAddressAreaItem 
                        key={item.code}
                        name={item.name}
                        clickFn={setCityCode.bind(this,item.code,item.name)}
                    />
                )
            case 3:
                return Object.values(location[formData.get('provinceCode')]['cities'][formData.get('cityCode')]['districts']).map( (item,idx) => 
                    <ShowAddressAreaItem 
                        name={item}
                        key={item}
                        clickFn={setDistrictCode.bind(this,Object.keys(location[formData.get('provinceCode')]['cities'][formData.get('cityCode')]['districts'])[idx],item)}
                    />
                )
            default:
                return
        }

    }


  
}

export default ShowAddressArea;