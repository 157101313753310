import {takeEvery,put} from 'redux-saga/effects'
import {getClassifyImage,getImageList,getFontList, getPriceRule, getCustomizedData} from '../../api'
import {types} from '../modules/customized'
import Immutable from 'immutable'
//获取图片分类数据
function* fetchClassifyImageData() {
    const classifyData = yield getClassifyImage();
    // console.log(classifyData)
    yield put({
        type: types.GET_CLASSIFY_IMAGE,
        data: classifyData
    })
    //获取系统插图分类数据后，默认第一个分类为当前选中的系统插图
    yield put({
        type: types.SET_CURRENT_IMAGE_ID,
        id: classifyData.system.chilendNode[0].MaterialID
    })
  
}

// 获取系统插图的分类列表
function* fetchImageList(action) {
    const imageList = yield getImageList(action.data)
    // console.log(action.data)
    yield put({
        type: types.GET_IMAGE_LIST,
        data: imageList,
        cateId: action.data.CateID,
        page: action.data.page
    })
    //获取系统插图后，把插图是否可加载的标识改成true
    yield put({
        type: types.SET_IMAGE_LOADING,
        flag: true
    })
}

//获取字体数据
function* fetchFontList(action) {
    const fontList = yield getFontList(action.path,action.data)
    yield put({
        type: types.GET_FONT_LIST,
        data: fontList,
        path: action.path
    })
    //获取字体数据后把字体是否可加载的标识改成true
    yield put({
        type: types.SET_FONT_LOADING,
        flag: true
    })
}

//获取计价规则
function* fetchPriceRule() {
    const priceRule = yield getPriceRule()
    yield put({
        type: types.GET_PRICE_RULE,
        priceRule
    })
}

//获取定制数据
function* fetchCustomizedData(action) {
    const customizedData = yield getCustomizedData(action.data)
    let newCustomizedData = JSON.parse(customizedData.info.MaterialStatus);
    //把定制数据转化为600×600格式
    newCustomizedData.forEach( data => {
        data.forEach( item => {
            // console.log(item)
            const {
                clientWidth,
                left,
                top,
                scaleX,
                scaleY
            } = item
            let per = 600 / Number(clientWidth)
            item.clientWidth = 600;
            item.left = Number(left) * per;
            item.top = Number(top) * per;
            item.scaleX = Number(scaleX) * per;
            item.scaleY = Number(scaleY) * per;
        })

    })
    yield put({
        type: types.SET_CUSTOMIZED_DATA,
        data: Immutable.List(newCustomizedData),
        pid: action.data.pid,
        userDesignId:  action.data.UserDesignID
    })

    yield put({
        type: types.SET_ORIGINAL_COLOR,
        color: customizedData.info.ColorValue
    })

 
}

export default function* index() {
    yield takeEvery(types.GET_CLASSIFY_IMAGE_REQUEST,fetchClassifyImageData)
    yield takeEvery(types.GET_IMAGE_LIST_REQUEST,fetchImageList)
    yield takeEvery(types.GET_FONT_LIST_REQUEST,fetchFontList)
    yield takeEvery(types.GET_PRICE_RULE_REQUEST,fetchPriceRule)
    yield takeEvery(types.GET_CUSTOMIZED_DATA_REQUEST,fetchCustomizedData)
}