import React, { Component } from 'react';
import styles from './style.module.less'
import LoginItem from './components/LoginItem';
import RegisterItem from './components/RegisterItem';

class Login extends Component {
    state = {
        showType: 'login'
    }
    render() {
        const {
            showType
        } = this.state
        console.log(showType)
        return (
            <div className={styles.body}>
                {
                    showType === 'login'
                    ?
                    <LoginItem {...this.state} changeShowType={this.changeShowType}/> 
                    :
                    <RegisterItem changeShowType={this.changeShowType} />
                }
            </div>
        );
    }

    //修改当前显示的注册或登录视图
    changeShowType = showType => this.setState({showType})

}

export default Login;