import Immutable, {Map} from 'immutable'


// types
export const types = {
    GET_DATA_REQUESTED: 'PRODUCT/GET_DATA_REQUESTED',   //请求商品数据时的types
    GET_DATA_SUCCESS: 'PRODUCT/GET_DATA_SUCCESS',   //获取商品数据后的types
    CHANGE_SIZE_NUMBER:  'PRODUCT/CHANGE_SIZE_NUMBER',  //修改尺码数量的types
}

// actions
export const actions = {
    // 请求商品数据的actions
    getProductData: (pid,userDesignId) => ({
        type: types.GET_DATA_REQUESTED,
        pid,
        userDesignId
    }),

    //修改尺码数量的actions
    changeSizeNumber: (pid,userDesignId,sizeId,value) => ({
        type: types.CHANGE_SIZE_NUMBER,
        pid,
        userDesignId,
        sizeId,
        value
    })


}

//转换productData为map数据
const convertProductData = action => {

    // 把尺码数据转化成尺码map
    let newSizeData;
    let newJson = {};
    action.data.size.forEach( item => {
        item.num = 0;
        item.StockNum = Number(item.StockNum);
        newJson[item.id] = item;
    })
    newSizeData = Immutable.fromJS({}).merge(Immutable.fromJS(newJson))
    //修改原数据中的size字段
    const newData = Immutable.fromJS(action.data).set('size',newSizeData)

    return  Map({}).setIn([action.pid,action.userDesignId],newData)
}


// reducer
const reducer = (state = Immutable.fromJS({}), action) => {
    switch(action.type) {
        //把获取到的商品数据存入store
        case types.GET_DATA_SUCCESS:
            return state.merge(convertProductData(action));

        // 根据pid,userDesignId,sizeId修改尺码数量
        case types.CHANGE_SIZE_NUMBER:
            return state
                   .updateIn([action.pid,action.userDesignId,'size',action.sizeId,'num'], num => num + action.value)
                   .updateIn([action.pid,action.userDesignId,'size',action.sizeId,'StockNum'], total => total - action.value)
        
        default:
            return state;
    }
}

export default reducer;


// selectors

// 根据pid和userDesignId筛选出商品所有数据
export const selectProductData = (state,pid,userDesignId) => state.getIn(['product',pid,userDesignId]) || Immutable.Map({})

// 根据pid和userDesignId筛选出当前商品图片数据
export const selectProductImage = (state,pid,userDesignId) => state.getIn(['product',pid,userDesignId,'img2d','Default']) || Immutable.List([])


// 根据pid和userDesignId筛选出当前商品尺码信息
export const selectProductSize = (state,pid,userDesignId) => state.getIn(['product',pid,userDesignId,'size']) || Immutable.Map([])


// 根据pid和userDesignId筛选出当前商品具体信息
export const selectProductDetail = (state,pid,userDesignId) => state.getIn(['product',pid,userDesignId,'productInfo']) || Immutable.Map({})
