import {fork} from 'redux-saga/effects'
import homeSaga from './home'
import cartSaga from './cart'
import productSaga from './product'
import customizedSaga from './customized'
import address from './address'
import order from './order'
import myCollection from './myCollection'
import myDesign from './myDesign'
import bodyInfo from './bodyInfo'

function* mySaga() {
    yield fork(homeSaga)
    yield fork(cartSaga)
    yield fork(productSaga)
    yield fork(customizedSaga)
    yield fork(address)
    yield fork(order)
    yield fork(myCollection)
    yield fork(myDesign)
    yield fork(bodyInfo)
}

export default mySaga;