import { takeEvery, put } from "redux-saga/effects";
import {types} from '../modules/bodyInfo'
import { getBodyInfo, deleteBodyInfo } from "../../api";


//获取体征列表数据
function* fetchBodyInfo() {
    const bodyInfo = yield getBodyInfo()
    yield put({
        type: types.GET_BODY_INFO,
        data: bodyInfo
    })
}

//删除体征记录
function* fetchDeleteBodyInfo(action) {
    const res = yield deleteBodyInfo({InfoID: action.infoId})
    if(res.status === 1) {
        alert('删除成功')
        yield put({
            type: types.DELETE_BODY_INFO,
            infoId: action.infoId
        })
    } else {
        alert('删除失败')
    }
}

export default function* index(){
    yield takeEvery(types.GET_BODY_INFO_REQUEST,fetchBodyInfo)
    yield takeEvery(types.DELETE_BODY_INFO_REQUEST,fetchDeleteBodyInfo)
}



