import Immutable, {OrderedMap} from 'immutable'

const initialState = Immutable.fromJS({
      personal: OrderedMap({}),
      team: OrderedMap({}),
      cartType: false, //false表示选中的为个人界面，true表示选择的为团队界面
      cartId: null
});


export const types = {
    GET_DATA_REQUESTED: 'CART/GET_DATA_REQUESTED',  //请求购物车数据的types
    GET_DATA: 'CART/GET_DATA',  //获得购物车数据后的types
    CHANGE_CART_TYPE: 'CART/CHANGE_CART_TYPE',  //改变购物车类型的types
    CHANGE_CART_ID: 'CART/CHANGE_CART_ID',  //改变当前选中的cartId的types
    CHANGE_SPEC_NUMBER_REQUEST: 'CART/CHANGE_SPEC_NUMBER_REQUEST', //请求改变当前选中的尺码数量的types
    CHANGE_SPEC_NUMBER: 'CART/CHANGE_SPEC_NUMBER', //改变当前选中的尺码数量的types
    DELETE_CART_RECORD_REQUEST: 'CART/DELETE_CART_RECORD_REQUEST',  //请求删除购物车记录的type
    DELETE_CART_RECORD: 'CART/DELETE_CART_RECORD', //成功删除购物车记录的types
    CHANGE_SELECTED_STATUS: 'CART/CHANGE_SELECTED_STATUS', //改变购物车记录被选中的状态
    CHANGE_SELECTED_ALL_STATUS: 'CART/CHANGE_SELECTED_ALL_STATUS', //改变购物车全选状态
}


export const actions = {
    // 请求购物车数据的actions
    getDataRequested: data => ({
        type: types.GET_DATA_REQUESTED,
        data
    }),
    // 改变购物车类型的actions
    changeCartType: cartType => ({
        type: types.CHANGE_CART_TYPE,
        cartType
    }),
    //改变当前选中的cartId
    changeCartId : cartId => ({
        type: types.CHANGE_CART_ID,
        cartId
    }),
    //请求改变当前选中的尺码数量
    setSpecNumber: (cartId,number,specIndex,data) => ({
        type: types.CHANGE_SPEC_NUMBER_REQUEST,
        cartId,
        number,
        specIndex,
        data
    }),

    // 请求删除购物车记录
    deleteCartRecordRequest: cartId => ({
        type: types.DELETE_CART_RECORD_REQUEST,
        cartId
    }),

    // 改变购物车数据被选中的状态
    changeSelectedStatus: (cartType,cartId) => ({
        type: types.CHANGE_SELECTED_STATUS,
        cartType,
        cartId
    }),

    // 改变购物车全选状态
    changeSelectedAllStatus: () => ({
        type: types.CHANGE_SELECTED_ALL_STATUS
    })

    
}

//把个人购物车数据转化为对象
const convertPersonalData = data => {
   let json = {};
   data.forEach( item => {
     item.selected = false;
     json[item.CartID] = item
   });
   return json;
}

//转化团队数据
const convertTeamData = data => {
    // console.log(data)
    let dataMap = {};
    Object.values(data).forEach( item => {
        Object.values(item).forEach( v => {
            // console.log(v)
            v.selected = false;
            dataMap[v.ProductInfo.CartID] = v;
        })
    });
    // console.log(dataMap)
    return dataMap
}

//全选或反选操作
const changeSelectedAllFn = state => {
    const cartType = state.get('cartType') ? 'team' : 'personal'
    if(isSelectedAllFn(state)) {
        return state.update(cartType, v => v.map( item => Immutable.fromJS(item).set('selected',false).toJS() ))
    } else {
        return state.update(cartType, v => v.map( item => Immutable.fromJS(item).set('selected',true).toJS() ))
    }
}

const isSelectedAllFn = state => {
        const cartType = state.get('cartType')
        const data = state.get(cartType ? 'team' : 'personal')
        let count = 0;
        data.forEach( item => {
            if(item.selected) {
                count++;
            }
        })
        if(count === data.size) {
            return true
        } else {
            return false
        }
     
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      //获取购物车数据
      case types.GET_DATA:
          if(state.get('cartType')) {
              return state.update('team', val => val.merge(convertTeamData(action.data)));
          } else {
              return state.update('personal', val => val.merge(convertPersonalData(action.data)));
          }
      // 改变购物车类型
      case types.CHANGE_CART_TYPE:
          return state.set('cartType',action.cartType)
      //改变当前选中的cartId
      case types.CHANGE_CART_ID:
          return state.set('cartId', action.cartId)
    //   改变当前选中尺码的数量
      case types.CHANGE_SPEC_NUMBER:
          
          return state.setIn(['personal',action.cartId,'SpecData',0,'spec',action.specIndex,'num'],action.number)
      
      case types.DELETE_CART_RECORD:
     
          return state.deleteIn(['personal',action.cartId])
      
      case types.CHANGE_SELECTED_STATUS:
          return state.updateIn([action.cartType,action.cartId,'selected'], v => !v)

      case types.CHANGE_SELECTED_ALL_STATUS:
        //   selectIsSelectedAll(state)
          
          return changeSelectedAllFn(state)
      default:
        return state;
    }
  };
  
  export default reducer;


// selectors
// 筛选出购物车的类型
export const selectCartType = state => state.getIn(['cart','cartType']);

//筛选出购物车的数据
export const selectCartData = state => {
    if(state.getIn(['cart','cartType'])) {
        return state.getIn(['cart','team']).toIndexedSeq();
    } else {
        return state.getIn(['cart','personal']).toIndexedSeq();
    }
}

//筛选出当前选中的cartId
export const selectCartId = state => state.getIn(['cart','cartId'])

//筛选出是否全选
export const selectIsSelectedAll = state => isSelectedAllFn(state.get('cart'))