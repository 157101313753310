import {takeEvery,put} from 'redux-saga/effects'
import {getCategoryData,getSlideImage,getCategoryProductList, getMenuList,} from '../../api'
import {types} from '../modules/home'

//获取款式分类的数据
function* fetchCategoryData() {
    const categoryData = yield getCategoryData();
    // console.log(categoryData)
    yield put({
        type: types.GET_CATEGORY_DATA,
        data: categoryData
    })
}

// 根据id获取该款式的商品数据
function* fetchCategoryProductList(action) {
    // console.log(action)
    const categoryProductList = yield getCategoryProductList(action.data);
    // console.log(categoryProductList)
    yield put({
        type: types.GET_CATEGORY_PRODUCT_LIST,
        data: categoryProductList.data,
        pid: action.data.id,
        page: action.data.page,
        total: categoryProductList.total
    })



}



//获取首页轮播图数据
 function* fetchSlideImage() {
    const slideImage = yield getSlideImage();
    yield put({
        type: types.GET_SLIDE_IMAGE,
        data: JSON.parse(slideImage.Value)
    })
}

//获取商城配置信息
function* fetchShopConfig() {
    const data = yield getMenuList()
    yield put({
        type: types.GET_SHOP_CONFIG,
        data
    })
}




export default function* index() {
    yield takeEvery(types.GET_CATEGORY_DATA_REQUEST,fetchCategoryData)
    yield takeEvery(types.GET_CATEGORY_PRODUCT_LIST_REQUEST,fetchCategoryProductList)
    yield takeEvery(types.GET_SLIDE_IMAGE_REQUEST,fetchSlideImage)
    yield takeEvery(types.GET_SHOP_CONFIG_REQUEST,fetchShopConfig)

}