import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class Pagination extends Component {

    //设置props的类型
    static propTypes = {
        index: PropTypes.number.isRequired,     //当前选中的图片索引
        setIndex: PropTypes.func.isRequired,    //更改索引的方法
        data: PropTypes.array.isRequired,       //轮播的图片数据
    }
    render() {
        const {
            index,
            setIndex,
            data
        } = this.props;
        return (
            <div className={styles.pagination}>
                {
                    data.map( (item,idx) => 
                        <span
                            key={item.Image}
                            style={{
                                background: idx === index ? '#00b2a5' : 'white',
                                border: idx === index ? '1px solid #00b2a5' : '1px solid #ccc'
                            }}
                            onClick={ () => setIndex(idx)}
                        ></span>    
                    )
                }
            </div>
        );
    }
}

export default Pagination;