import React, { Component } from 'react';
import styles from './style.module.less'


class LeftArea extends Component {
    render() {
        return (
            <div className={styles.body}>
                <div className={styles.innerDiv}>
                    <div className={styles.showColor}></div>
                </div>
            </div>
        );
    }
}

export default LeftArea;