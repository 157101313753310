import React, { Component } from 'react';
import styles from './style.module.less'
import t01 from '../../../../images/tech01.png'
import t02 from '../../../../images/tech02.png'
import t03 from '../../../../images/tech03.png'
import t04 from '../../../../images/tech04.png'
import t05 from '../../../../images/tech05.png'
import t06 from '../../../../images/tech06.png'
import ShowTech from './TechItem'

const data = [
    {
        src: t01,
        title: '印染技术',
        describe: '采用数码直喷技术，经反复洗涤验证，图案不褪色，保持优良触感。'
    },
    {
        src: t02,
        title: '安全喷墨',
        describe: '油墨安全无毒，通过OTS100 Baby级别安全认证，可用来制作婴儿用品。'
    },
    {
        src: t03,
        title: '成衣品质',
        describe: '出口日本的成衣品质，给您更舒适安心的穿着体验。'
    },
    {
        src: t04,
        title: '个性定制',
        describe: '用户只需脑洞大开，即可利用在线定制工具，创做出独一无二的个性化服装。'
    },
    {
        src: t05,
        title: '售后服务',
        describe: '致力于提供高质量的售后服务，把用户满意度放在首要位置。'
    },
    {
        src: t06,
        title: '赚取利润',
        describe: '全产业链无忧服务，让您不费心、不费力，轻松获取丰厚利润。'
    },
]

class Technology extends Component {
    render() {
        return (
            <div className={styles.body}>
                <ul className={styles.center}>
                    {
                        data.map( item => 
                            <ShowTech 
                                key={item.src}
                                data={item}
                            />
                        )
                    }
                </ul>
            </div>
        );
    }
}

export default Technology;