import { createStore, applyMiddleware,compose} from 'redux'
import rootReducer from './modules'
import createSagaMiddleware from 'redux-saga'
import mySagas from './sagas'



const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = 
    typeof window === 'object' && process.env.NODE_ENV !== "production" && 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

//添加中间键的enhancer
const enhancer = composeEnhancers(
    applyMiddleware(sagaMiddleware)
)

//创建store
const store = createStore(rootReducer, enhancer);

// 运行sagas
sagaMiddleware.run(mySagas)

export default store;
