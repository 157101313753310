import React, { Component } from 'react';
import styles from './style.module.less'
import Search from './Search'

class SearchBar extends Component {
    render() {
        return (
            <div className={styles.body}>
                <div className={styles.center}>
                    <div className={styles.logoArea}></div>
                    <Search />
                </div>
            </div>
        );
    }
}

export default SearchBar;