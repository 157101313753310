import React, { Component } from 'react';
import {
    Technology,
    CompanyInfo
} from './components'
import PropTypes from 'prop-types'

class Footer extends Component {
    static propTypes = {
        showTech: PropTypes.bool
    }
    render() {
        const {showTech} = this.props;
        return (
            <div>
                {showTech ? <Technology /> : ''}
                <CompanyInfo />
            </div>
        );
    }
}

export default Footer;