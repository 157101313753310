import React, { Component } from 'react';
import styles from './style.module.less'

const advantageData = [
    {
        name: '免输入',
        icon: 'iconfont icon-bianji3'
    },
    {
        name: '更快',
        icon: 'iconfont icon-gengkuai_huaban'
    },
    {
        name: '更安全',
        icon: 'iconfont icon-genganquan'
    },
]

class WeChatLogin extends Component {
    render() {
        return (
            <div className={styles.weChatLogin}>
                <div className={styles.logo}></div>
                <div className={styles.QRCode}></div>
                <div className={styles.hint}>打开手机微信,扫描二维码</div>
                <div className={styles.advantage}>
                    {
                        advantageData.map( item => 
                            <div key={item.name} className={styles.advantageItem} >
                                <div className={`${item.icon} ${styles.icon}`}></div>
                                <div className={styles.title}>{item.name}</div>
                            </div>    
                        )
                    }
                </div>
            </div>
        );
    }
}

export default WeChatLogin;