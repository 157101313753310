import React, { Component } from 'react';
import {
    Header,
    SearchBar,
    MainMenu
} from './components'

class Navigation extends Component {
   
    render() {
        const {
            hideCategory,   //是否隐藏分类菜单
        } = this.props
        return (
            <div>
                <Header />
                <SearchBar />
                {
                    hideCategory
                    ?
                    ''
                    :
                    <MainMenu />
                }
            </div>
        );
    }
}

export default Navigation;