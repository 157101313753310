

import {takeEvery,put} from 'redux-saga/effects'
import {types} from '../modules/address'
import {getAddressList, createAddress, setDefaultAddress, deleteAddress, editAddress} from '../../api'

//获取地址数据
function* fetchAddress(action) {
    const addressData = yield getAddressList(action.data);
    yield put({
        type: types.GET_ADDRESS_DATA,
        data: addressData
    })
}

//创建新的收货人地址
function* fetchCreateAddress(action) {
    const result = yield createAddress(action.data)
    if(result.status === 1) {
        yield put({
            type: types.CREATE_ADDRESS,
            data: action.data,
            addressId:result.AddressIDD
        })
    }
}


//设置默认地址
function* fetchSetDefaultAddress(action) {
    // console.log(action)
    const result = yield setDefaultAddress({id: action.addressId})
    if(result.status === 1) {
        yield put({
            type: types.SET_DEFAULT_ADDRESS,
            addressId: action.addressId
        })
    }
}

// 删除地址的方法
function* fetchDeleteAddress(action) {
    const result = yield deleteAddress({id: action.addressId})
    if(result.status === 1) {
        yield put({
            type: types.DELETE_ADDRESS,
            addressId: action.addressId
        })
    }
}

// 编辑地址的方法
function* fetchEditAddress(action) {
    const result = yield editAddress(action.data)
    if(result.status === 1) {
        yield put({
            type: types.EDIT_ADDRESS,
            data: action.data
        })
    }
}


export default function* index() {
    yield takeEvery(types.GET_ADDRESS_DATA_REQUEST,fetchAddress)
    yield takeEvery(types.CREATE_ADDRESS_REQUEST, fetchCreateAddress)
    yield takeEvery(types.SET_DEFAULT_ADDRESS_REQUEST, fetchSetDefaultAddress)
    yield takeEvery(types.DELETE_ADDRESS_REQUEST, fetchDeleteAddress)
    yield takeEvery(types.EDIT_ADDRESS_REQUEST, fetchEditAddress)
}