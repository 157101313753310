import React, { Component } from 'react';
import styles from './style.module.less'
import { accountLogin } from '../../../../api';
import { connect } from 'react-redux';
import {actions as homeActions} from '../../../../redux/modules/home'

@connect(null,homeActions)
class AccountLogin extends Component {
    state = {
        userName: '',
        password: ''
    }
    render() {
        const {
            userName,
            password
        } = this.state
        return (
            <div className={styles.accountLogin}>
                <div className={styles.logo}></div>
                <div className={styles.userName}>
                    <div className={`iconfont icon-gerenzhongxinwoderenwubiaozhuntouxianxing ${styles.icon}`}></div>
                    <input value={userName} onChange={this.setUserName} placeholder='邮箱/用户名/登录手机' type='text' className={styles.input} />
                </div>
                <div className={styles.userName}>
                    <div className={`iconfont icon-zaicishurumima ${styles.icon}`}></div>
                    <input value={password} onChange={this.setPassword} placeholder='密码' type='password' className={styles.input} />
                </div>
                <div className={styles.loginButton} onClick={this.loginFn}>登录</div>
            </div>
        );
    }

    //设置用户名的内容
    setUserName = ev => this.setState({userName:ev.target.value})

    //设置密码的内容
    setPassword = ev => this.setState({password:ev.target.value})

    //登录方法
    loginFn = () => {
        const {
            userName,
            password
        } = this.state
        const {
            getShopConfigRequest
        } = this.props
        const re = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}$|17[0-9]{1}[0-9]{8}$/;
        if (!re.test(userName)) {
            alert("请输入正确的手机格式！");
            return false;
        }
        accountLogin({
            telNum: userName,
            pwd: password
        }).then( res => {
            console.log(res);
            if (res === -1) {
                alert('此手机号未注册，请重新输入！');
                return false;
            }
            else if (res === 1) {
                getShopConfigRequest();
                // getMenuListData().then( res => {
                //     if(res.userInfo) {
                //         const {push} = this.props.history;
                //         Toast.success('登录成功',1);
                //         setTimeout( () => {
                //             if(location.pathname.includes('login')) {
                //                 push('/mobile');
                //             } else {
                //                 push(location.pathname)
                //             }
                //         },1000)
                //     }
                // })
                
            }
            else if (res === 2) {
                this.setState({
                    password: ''
                })
                alert('密码错误，请重新输入！');
                return false;
            }
            else if (res === 3) {
                alert('该账号已经被冻结，登录失败！');
                return false;
            } else if (res === 4) {
                alert('该账号不存在！',1);
                return false;
            } else if (res === 5) {
                alert('账号状态为待审核，请联系管理员！');
                return false;
            } else if (res === 6) {
                alert('账号审核未通过，请联系管理员！');
                return false;
            } else {
                alert('登录失败，请稍后再试！');
                return false;
            }
        })
    }


}

export default AccountLogin;