import React, { Component } from 'react';
import styles from './style.module.less'
import {withRouter} from 'react-router-dom'

@withRouter
class RightArea extends Component {
    render() {
        return (
            <div className={styles.body}>
                <div className={styles.innerDiv}>
                    <h1>Create Custom Clothing & Accessories</h1>
                    <h2>定制属于自己的服饰</h2>
                    <div className={styles.startDesign} onClick={this.goToCustomizedPage}>开始你的设计</div>
                </div>
            </div>
        );
    }

    //跳转到定制页面
    goToCustomizedPage = () => {
        console.log(this.props)
        const {
            push
        } = this.props.history
        console.log('/customized/12157/0')
        push('/customized/12157/0')
    }

}

export default RightArea;