import {takeEvery,put} from 'redux-saga/effects'
import {getProductInfo} from '../../api'
import {types} from '../modules/product'

// 根据pid和userDesignId获取商品数据
function* fetchData(action) {
    const {pid,userDesignId} = action
    const data = yield getProductInfo({id:pid,UserDesignID:userDesignId});
    yield put({
        type: types.GET_DATA_SUCCESS,
        data,
        pid,
        userDesignId
    })
   
}

export default function* index() {
    yield takeEvery(types.GET_DATA_REQUESTED,fetchData)
}