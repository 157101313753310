import React, { Component } from 'react';
import styles from './style.module.less'
import TabItem from './TabItem'

const tabInfo = [
    {
        name: '我的收藏',
        icon: 'icon-shoucang2',
        path: '/myCollection'
    },
    // {
    //     name: '我的分享',
    //     icon: 'icon-09',
    //     path: '/cart'
    // },
    {
        name: '我的设计',
        icon: 'icon-chima',
        path: '/myDesign'
    },
    {
        name: '我的订单',
        icon: 'icon-wodedingdan',
        path: '/orderList'
    },
    {
        name: '我的购物车',
        icon: 'icon-gouwuche',
        path: '/cart'
    },
    {
        name: '我的账户',
        icon: 'icon-gerenzhongxinwoderenwubiaozhuntouxianxing',
        path: '/accountInfo'
    },
    
]

class Tab extends Component {
    render() {
        return (
            <div className={styles.body}>
                {
                    tabInfo.map( item => 
                        <TabItem 
                            data={item}
                            key={item.icon}
                        />
                    )
                }
            </div>
        );
    }
}

export default Tab;