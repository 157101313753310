import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

@withRouter
class Bottom extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,   //商品名称
        price: PropTypes.string.isRequired, //商品价格
        pid: PropTypes.string.isRequired,   //商品id
        userDesignId: PropTypes.string.isRequired, //商品设计id
    }
    render() {
        const {
            name,
            price
        } = this.props;
        return (
            <div className={styles.bottom}>
                <div className={styles.name}>{name}</div>
                <div className={styles.price}>￥{price}</div>
                <div className={styles.operation}>
                    <div className={styles.goToDesign} style={{borderRight: 'none'}} onClick={this.goToCustomized}>进入设计</div>
                    <div className={styles.goToDesign} onClick={this.addCart} >加购物车</div>
                </div>
            </div>
        );
    }

    //进入定制页面
    goToCustomized = () => {
        const {
            history:{push},
            pid,
            userDesignId
        } = this.props
        push(`/customized/${pid}/${userDesignId}`)
    }

    //加入购物车方法 
    addCart = () => {
        const {
            history:{push},
            pid,
            userDesignId
        } = this.props
        push(`/product/${pid}/${userDesignId}`)
    }
}

export default Bottom;