import React, { Component } from 'react';
import styles from './style.module.less'
import { getMessage,registerAccount } from '../../../../api';
import PropTypes from 'prop-types'

class RegisterItem extends Component {
    static propTypes = {
        changeShowType: PropTypes.func, //切换显示登录或注册的视图
    }
    state = {
        phone: '',
        userName: '',
        password: '',
        password2: '',
        graphCode: '',
        messageCode: '',
        verifyCode: '/verify.php',
    }
    render() {
        const {
            phone,userName,password,password2,graphCode,messageCode,verifyCode
        } = this.state
        const {
            changeShowType
        } = this.props
        return (
            <div className={styles.body}>
                <div className={styles.logo}></div>
                <div className={styles.loginButton} onClick={() => changeShowType('login')}>已有帐号,请点此登录</div>
                <div className={styles.inlineStyle}>
                    <div className={styles.title}>手机号</div>
                    <input 
                        type='text' 
                        className={styles.inputStyle} 
                        value={phone} 
                        onChange={e => this.setState({phone: e.target.value})} 
                        placeholder='请输入手机号'
                    />
                </div>
                <div className={styles.inlineStyle}>
                    <div className={styles.title}>用户名</div>
                    <input 
                        type='text' 
                        className={styles.inputStyle} 
                        value={userName} 
                        onChange={e => this.setState({userName: e.target.value})} 
                        placeholder='请输入用户名'
                    />
                </div>
                <div className={styles.inlineStyle}>
                    <div className={styles.title}>密码</div>
                    <input 
                        type='text' 
                        className={styles.inputStyle} 
                        value={password} 
                        onChange={e => this.setState({password: e.target.value})} 
                        placeholder='请输入密码'
                    />
                </div>
                <div className={styles.inlineStyle}>
                    <div className={styles.title}>确认密码</div>
                    <input 
                        type='text' 
                        className={styles.inputStyle} 
                        value={password2} 
                        onChange={e => this.setState({password2: e.target.value})} 
                        placeholder='请再次输入密码'
                    />
                </div>
                <div className={styles.inlineStyle}>
                    <div className={styles.title}>图形验证码</div>
                    <input 
                        type='text' 
                        className={styles.graphInput} 
                        value={graphCode} 
                        onChange={e => this.setState({graphCode: e.target.value})} 
                        placeholder='请输入图形验证码'
                    />
                    <img onClick={this.changeVerifyCode} alt='' src={verifyCode} className={styles.verifyImage} />
                </div>
                <div className={styles.inlineStyle}>
                    <div className={styles.title}>短信验证码</div>
                    <input 
                        type='text' 
                        className={styles.messageInput} 
                        value={messageCode} 
                        onChange={e => this.setState({messageCode: e.target.value})} 
                        placeholder='请输入短信验证码'
                    />
                    <div className={styles.getMessageCode} onClick={this.getMessageCode}>发送验证码</div>
                </div>
                <div className={styles.registerButton} onClick={this.registerFn}>注册</div>
            </div>
        );
    }

    //获取新的图形验证码的方法
    changeVerifyCode = () => {
        const {verifyCode} = this.state
        this.setState({
            verifyCode: `${verifyCode}?${Math.random()}`
        })
    }

    //检查手机格式是否正确
    checkPhone = () => {
        const {phone} = this.state
        const re = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}$|17[0-9]{1}[0-9]{8}$/;
        if (!re.test(phone)) {
            alert("请输入正确的手机格式！");
            this.setState({phone:''})
            return false;
        }
    }
    //获取短信验证码
    getMessageCode = () => {
        const {phone} = this.state
        this.checkPhone()
        getMessage({
            phone,
            sms_type: 1
        }).then(res => {
            alert(res.info)
        })
    }
    //注册的方法
    registerFn = () => {
        this.checkPhone()
        const {
            phone,
            userName,
            password,
            password2,
            graphCode,
            messageCode,
        } = this.state
        const {
            changeShowType
        } = this.props
        registerAccount({
            Mobile: phone,
            RealName: userName,
            Pwd: password,
            ConfirmPwd: password2,
            code: messageCode,
            img_verify: graphCode
        }).then(res => {
            if(res.status === 1) {
                alert('注册成功')
                changeShowType('login')
            }else {
                alert(res.info)
            }
        })
    }

}

export default RegisterItem;