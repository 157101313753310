
import Loadable from 'react-loadable'
import {Loading} from '../components'

//引入首页
const Home = Loadable({
    loader: () => import('./Home'),
    loading: Loading,
});

//引入商品详情页
const Product = Loadable({
    loader: () => import('./Product'),
    loading: Loading,
});

//引入购物车页面
const Cart = Loadable({
    loader: () => import('./Cart'),
    loading: Loading,
});

//引入商品分类页面
const Category = Loadable({
    loader: () => import('./Category'),
    loading: Loading,
});

//引入定制页面
const Customized = Loadable({
    loader: () => import('./Customized'),
    loading: Loading
})

//引入结算页面
const CheckOrder = Loadable({
    loader: () => import('./CheckOrder'),
    loading: Loading
})

//引入支付页面
const Payment = Loadable({
    loader: () => import('./Payment'),
    loading: Loading
})

// 引入订单列表页面
const OrderList = Loadable({
    loader: () => import('./OrderList'),
    loading: Loading
})

//引入选择支付方法页面
const ChoosePayType = Loadable({
    loader: () => import('./ChoosePayType'),
    loading: Loading
})

//引入地址页面
const Address = Loadable({
    loader: () => import('./Address'),
    loading: Loading
})

//引入个人中心页面
const PersonalCenter = Loadable({
    loader: () => import('./PersonalCenter'),
    loading: Loading
})

//引入消息通知页面
const Message = Loadable({
    loader: () => import('./Message'),
    loading: Loading
})


//引入账户信息页面
const AccountInfo = Loadable({
    loader: () => import('./AccountInfo'),
    loading: Loading
})

//引入我的积分页面
const MyScore = Loadable({
    loader: () => import('./MyScore'),
    loading: Loading
})

//引入我的收藏页面
const MyCollection = Loadable({
    loader: () => import('./MyCollection'),
    loading: Loading
})

//引入我的作品页面
const MyDesign = Loadable({
    loader: () => import('./MyDesign'),
    loading: Loading
})

//引入我的体征页面
const BodyInfo = Loadable({
    loader: () => import('./BodyInfo'),
    loading: Loading
})

//引入优惠券页面
const Coupon = Loadable({
    loader: () => import('./Coupon'),
    loading: Loading
})

export {
    Home,
    Product,
    Cart,
    Category,
    Customized,
    CheckOrder,
    Payment,
    OrderList,
    ChoosePayType,
    Address,
    PersonalCenter,
    Message,
    AccountInfo,
    MyScore,
    MyCollection,
    MyDesign,
    BodyInfo,
    Coupon,
}