import React, { Component } from 'react';
import styles from './style.module.less'

class Bottom extends Component {
    render() {
        const {
            changeShowType
        } = this.props
        return (
            <div onClick={() => changeShowType('register')} className={styles.bottom}>
                快速注册                
            </div>
        );
    }
}

export default Bottom;