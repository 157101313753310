import React, { Component } from 'react';
import styles from './style.module.less'
import InfoTab from './InfoTab.jsx'

const data = [
    {
        title: '使用指南',
        sub: [
            {
                title: '购物流程'
            },
            {
                title: '在线定制'
            },
            {
                title: '加入鲜动'
            },
        ]
    },
    {
        title: '售后服务',
        sub: [
            {
                title: '退/换货'
            },
            {
                title: '配送方式'
            },
            {
                title: '常见问题'
            },
        ]
    },
    {
        title: '政策条款',
        sub: [
            {
                title: '协议政策'
            },
            {
                title: '隐私条款'
            },
            {
                title: '版权协议'
            },
        ]
    },
    {
        title: '政策条3款',
        sub: [
            {
                title: '协议34政策'
            },
            {
                title: '隐4234私条款'
            },
            {
                title: '版权423协议'
            },
        ]
    },
    {
        title: '政策3条款',
        sub: [
            {
                title: '协议政策'
            },
            {
                title: '隐私条款'
            },
            {
                title: '版权2协议'
            },
            {
                title: '版权协3423议'
            },
            {
                title: '版权423423协议'
            },
        ]
    },

]

class CompanyInfo extends Component {
    render() {
        return (
            <div className={styles.body}>
                <div className={styles.center}>
                    {
                        data.map( item => 
                            <InfoTab key={item.title} data={item} />
                        )
                    }
                </div>
            </div>
        );
    }
}

export default CompanyInfo;