import React, { Component } from 'react';
import styles from './style.module.less'
import {
    Tab
} from './components'

class Toolbar extends Component {
    render() {
        return (
            <div className={styles.body}>
                <Tab />
            </div>
        );
    }
}

export default Toolbar;