import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'
import Top from './Top'
import Bottom from './Bottom'

class GoodsItem extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,  //具体某个商品的数据
    }
    render() {
        const {
            data,
        } = this.props
        return (
            <div className={styles.body} >
                <Top
                    src={data.Thumb}
                    pid={data.ProductID}
                    userDesignId={data.UserDesignID || '0'}
                />
                <Bottom 
                    name={data.PName}
                    price={data.UserPrice}
                    pid={data.ProductID}
                    userDesignId={data.UserDesignID || '0'}
                />
            </div>
        );
    }
}

export default GoodsItem;