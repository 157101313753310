import React, { Component } from 'react';
import routes from './routes'
import {Route,Switch,Redirect, withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import {actions as homeActions, selectUserInfo, selectShopConfig} from './redux/modules/home'
import {
  Login, Loading
} from './components'
import Home from './pages/Home';

const mapState = state => ({
    userInfo: selectUserInfo(state),
    shopConfig: selectShopConfig(state)
})

@withRouter
@connect(mapState,homeActions)
class App extends Component {
  componentDidMount() {
      const {
        getShopConfigRequest
      } = this.props
      getShopConfigRequest();
  }
  render() {
    const {
      userInfo,
      location:{pathname},
      shopConfig
    } = this.props

    return (
      <Switch>
        {
          routes.map( route => 
            <Route 
                exact={route.exact} 
                path={route.pathname}
                key={route.pathname} 
                children={
                  routerProps => {
                    if(!shopConfig) {
                      return <Loading />
                    } else if(pathname === '/' || pathname.includes('customized')) {
                      return <route.component {...routerProps} />
                    } else if(userInfo.Tourist && userInfo.Tourist === '1'){
                      console.log('未登录')
                      return (
                        <>
                          <Home />
                          <Login />
                        </>
                      )
                    } else {
                      return <route.component {...routerProps} />
                    }
                  }
                }
            />
          )
        }
         <Redirect
              exact
              to="/"
          />
      </Switch>
    );
  }
}

export default App;