import Immutable from 'immutable'


//设置初始的state
const initialState = Immutable.fromJS({
    bodyInfoData: {},  //体征数据
    bodyInfoList: [], //体征infoId的有序排列
})


// types
export const types = {
    GET_BODY_INFO_REQUEST: 'BODY_INFO/GET_BODY_INFO_REQUEST', //请求获取体征数据的types
    GET_BODY_INFO: 'BODY_INFO/GET_BODY_INFO', //成功获取体征数据的types
    DELETE_BODY_INFO_REQUEST: 'BODY_INFO/DELETE_BODY_INFO_REQUEST',  //请求删除尺码的types
    DELETE_BODY_INFO: 'BODY_INFO/DELETE_BODY_INFO', //成功删除尺码的types
}


// actions
export const actions = {
    //请求获取体征数据
    getBodyInfoRequest: () => ({
        type: types.GET_BODY_INFO_REQUEST
    }),

    // 请求删除尺码
    deleteBodyInfoRequest: infoId => ({
        type: types.DELETE_BODY_INFO_REQUEST,
        infoId
    }),

}


// 转化体征数据
const convertBodyInfo = data => {
    let dataMap = {}
    let dataList = []
    data.forEach( item => {
        dataList.push(item.id)
        dataMap[item.id] = item
    })
    return {
        dataMap,
        dataList
    }
}


// reducer
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case types.GET_BODY_INFO:
            
            return state.mergeIn(['bodyInfoData'],convertBodyInfo(action.data).dataMap)
                        .mergeIn(['bodyInfoList'],convertBodyInfo(action.data).dataList)

        case types.DELETE_BODY_INFO:
            // console.log(action)
            return state.deleteIn(['bodyInfoData',action.infoId])
        
        default:
            return state
    }
}

export default reducer

//selectors

//筛选出体征列表数据
export const selectBodyInfo = state => {
    let data = Immutable.List([])
    state.getIn(['bodyInfo','bodyInfoList']).forEach( item => {
        let bodyInfoItem = state.getIn(['bodyInfo','bodyInfoData',item])
        if(bodyInfoItem) {
            data = data.push(bodyInfoItem)
        }
    })
    
    return data
}