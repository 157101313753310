import { takeEvery, put } from "redux-saga/effects";
import {types} from '../modules/order'
import { getOrderList } from "../../api";


// 获取订单数据
function* fetchOrderList(action) {
    const orderList = yield getOrderList(action.data)
    yield put({
        type: types.GET_ORDER_LIST,
        data: orderList.data
    })

    yield put({
        type: types.SET_TOTAL_ORDER,
        total: orderList.count
    })

}

export default function* index() {
    yield takeEvery(types.GET_ORDER_LIST_REQUEST,fetchOrderList)
}