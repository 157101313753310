import React, { Component } from 'react';
import styles from './style.module.less'
import MenuItem from './MenuItem'
import {actions as homeActions, selectCategoryData} from '../../../../redux/modules/home'
import {connect} from 'react-redux'

const mapState = state => ({
    categoryData: selectCategoryData(state)
})

@connect(mapState,homeActions)
class MainMenu extends Component {
    componentDidMount() {
        const {
            getCategoryDataRequest,
            categoryData
        } = this.props
        if(!categoryData || categoryData.size === 0) {
            getCategoryDataRequest()
        }
    }

    render() {
        const {
            categoryData
        } = this.props
        // console.log(categoryData)
        return (
            <div className={styles.mainMenu}>
                <ul className={styles.center}>
                    {
                        categoryData && categoryData.map( item => 
                            <MenuItem 
                                key={item.id}
                                data={item}
                            />
                        )
                    }
                </ul>
            </div>
        );
    }
}

export default MainMenu;