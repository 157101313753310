import {takeEvery,put} from 'redux-saga/effects'
import {types} from '../modules/myCollection'
import { getMyCollection } from '../../api'


//获取我的收藏数据
function* fetchMyCollectionData(action) {
    const {page,rows} = action
    const collectionData = yield getMyCollection({page,rows})
    yield put({
        type: types.GET_COLLECTION_DATA,
        data: collectionData
    })
    yield put({
        type: types.SET_TOTAL,
        total: collectionData.total
    })

}

export default function* index() {
    yield takeEvery(types.GET_COLLECTION_DATA_REQUEST,fetchMyCollectionData)
}