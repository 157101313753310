import {takeEvery,put} from 'redux-saga/effects'
import {types} from '../modules/myDesign'
import { getMyDesign } from '../../api'


//获取我的作品数据
function* fetchMyDesignData(action) {
    const {page,rows} = action
    const designData = yield getMyDesign({page,rows})
    yield put({
        type: types.GET_MY_DESIGN_DATA,
        data: designData
    })
    yield put({
        type: types.SET_TOTAL,
        total: designData.total
    })
}

export default function* index() {
    yield takeEvery(types.GET_MY_DESIGN_DATA_REQUEST,fetchMyDesignData)
}