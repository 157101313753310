import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class MoveButton extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,  //识别当前按钮朝向
        setIndex: PropTypes.func.isRequired, //设置商品栏位移的方法
        index: PropTypes.number.isRequired, //商品栏的位移
        maxIndex: PropTypes.number.isRequired,  //商品栏位移的最大长度
    }
    render() {
        const {
            type,
        } = this.props
        return (
            <div className={styles.moveButton}>
                <div 
                    className={`iconfont icon-fanhui ${styles.icon}`}
                    style={{
                        transform: type === 'right' ? 'scaleX(-1)' : ''
                    }}
                    onClick={this.changePosition}
                ></div>
            </div>
        );
    }

    //改变商品栏的位移
    changePosition = () => {
        const {
            index,
            setIndex,
            maxIndex,
            type
        } = this.props;
        // console.log(index,maxIndex)
        if(type === 'right') {
            let newIndex = index+1;
            if(newIndex < maxIndex ) setIndex(newIndex);
        } else {
            let newIndex = index-1;
            if(newIndex >= 0 ) setIndex(index-1);
        }

    }

}

export default MoveButton;