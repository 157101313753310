import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'
import SubMenuItem from './SubMenuItem'

class MenuItem extends Component {
    constructor() {
        super();
        this.state = {
            show: false
        }
    }
    static propTypes = {
        data: PropTypes.object.isRequired
    }
    render() {
        const {data} = this.props;
        const {show} = this.state;
        return (
            <li className={styles.liStyle} onMouseOver={this.mouseOverFn} onMouseLeave={this.mouseLeaveFn}>
                <span>{data.text}</span>
                {show ? <SubMenuItem  data={data.children} /> : ''}
            </li>
        );
    }

    //设置是否显示二级菜单栏
    setShow = show => {
        this.setState({
            show
        })
    }

    //鼠标触摸该菜单触发事件
    mouseOverFn = () => {
        this.setShow(true);
    }
    //鼠标离开该菜单触发事件
    mouseLeaveFn = () => {
        this.setShow(false);
    }
}

export default MenuItem;