import React, { Component } from 'react';
import styles from './style.module.less'

class Search extends Component {
    render() {
        return (
            <div className={styles.search}>
                <div className={styles.title}>综合搜索</div>
                <div className={styles.input}>
                    <input type='text' className={styles.inputText} placeholder='请输入要搜索的内容' />
                </div>
                <div className={styles.searchBtn}>搜索</div>
            </div>
        );
    }
}

export default Search;