import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'
import Theme from './Theme'

class Content extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,   //主题数据
    }
    render() {
        const {
            data
        } = this.props;
        return (
            <div className={styles.content}>
                {
                    data.map( (item,idx) => 
                        <Theme 
                            key={idx}
                            data={item}
                        />    
                    )
                }
            </div>
        );
    }
}

export default Content;