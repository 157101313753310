import Immutable from 'immutable'

//设置初始的state
const initialState = Immutable.fromJS({
    addImageFn: () => {}, //添加图片到canvas的方法
    addFontFn: () => {}, //添加字体到canvas的方法
    changeFontColorFn: () => {}, //改变字体颜色的方法
    getCanvas: () => {}, //返回当前操作的canvas对象的方法
    calculateOutlineFn: () => {}, //计算canvas上元素是否超出绘制框的方法
    clearCanvasFn: () => {},    //清空canvas上元素的方法
    scaleFn: () => {} , //放大或缩小设计区域
    cloneElementFn: () => {}, //复制canvas上被选中的元素
    updateCustomizedDataFn: () => {}, //更新定制信息的方法

    customizedData: {},   //定制数据
    customizedImageData: {}, //定制后的产品图片
    originalColor: null, //该款式所选的颜色
    elementPrice: 0, //设计元素的总价
    newUserDesignId: null, //新生成的userDesignId
    priceRule: {},  //字体和插图的计价规则



    //0表示显示面料选择视图，1表示显示尺码介绍视图, 2表示显示添加图片界面，
    //3表示显示添加文本界面，4表示显示上传图片界面,5表示计价明细界面
    //6表示加入购物车界面
    operationStatus: 0, 

    //插图部分数据----
    imageClassifyId: 0, //0表示选中分类图片，1表示选中标签，2表示选中个人图片
    classifyImage: [],    //图片分类数据
    imageList: Immutable.Map({}), //所有系统插图数据
    imagePage: Immutable.Map({}), //系统插图已加载过的页面
    currentImageId: null, //当前选中的插图分类id
    imageLoading: true,  //判断插图是否可以加载的标识
    //---插图部分数据

    //字体部分数据----
    fontList: Immutable.Map({
        cnIndex: 1,
        enIndex: 1,
        twIndex: 1,
        fontLoading: true

    }), //所有的字体数据

    currentFontType:  {
        name: '简体',
        path: 'cnthn',
        text: '鲜动定制2019',
        indexType: 'cnIndex'
    },   //当前选中的字体类别

    inputValue: 'USHIRT', //记录当前输入框的字体内容
    //---字体部分数据

    selectedImageIndex: 0,  //当前选中的定制底图上的索引
    showLogin: false, 
})

//types
export const types = {
    SET_ADD_IMAGE_FN: 'CUSTOMIZED/SET_ADD_IMAGE_FN', //设置添加图片到canvas的方法
    SET_ADD_FONT_FN: 'CUSTOMIZED/SET_ADD_FONT_FN',  //设置添加字体到canvas的方法
    SET_CHANGE_FONT_COLOR_FN: 'CUSTOMIZED/SET_CHANGE_FONT_COLOR_FN', //设置字体颜色的方法
    SET_CANVAS_FN: 'CUSTOMIZED/SET_CANVAS_FN', //设置当前操作的canvas
    SET_CALCULATE_OUTLINE_FN: 'CUSTOMIZED/SET_CALCULATE_OUTLINE_FN', //设置计算canvas上元素是否超出绘制框的方法的types
    SET_CLEAR_CANVAS_FN: 'CUSTOMIZED/SET_CLEAR_CANVAS_FN',//设置清空canvas方法的types
    SET_SCALE_FN: 'CUSTOMIZED/SET_SCALE_FN', //设置放大或缩小设计区域的types
    SET_CLONE_ELEMENT_FN: 'CUSTOMIZED/SET_CLONE_ELEMENT_FN', //设置克隆canvas上被选中元素的types
    SET_UPDATE_CUSTOMIZED_DATA_FN: 'CUSTOMIZED/SET_UPDATE_CUSTOMIZED_DATA_FN', //更新定制信息方法的types

    SET_CUSTOMIZED_DATA: 'CUSTOMIZED/SET_CUSTOMIZED_DATA', //设置定制数据的types
    SET_CUSTOMIZED_IMAGE_DATA: 'CUSTOMIZED/SET_CUSTOMIZED_IMAGE_DATA', //设置定制后的产品图片
    SET_ORIGINAL_COLOR: 'CUSTOMIZED/SET_ORIGINAL_COLOR', //设置当前款式所选颜色
    SET_NEW_USER_DESIGN_ID: 'CUSTOMIZED/SET_NEW_USER_DESIGN_ID', //设置新的userDesignID
    GET_PRICE_RULE_REQUEST: 'CUSTOMIZED/GET_PRICE_RULE_REQUEST', //请求获取计价规则的types
    GET_PRICE_RULE: 'CUSTOMIZED/GET_PRICE_RULE', //成功获取计价规则的types
    GET_CUSTOMIZED_DATA_REQUEST: 'CUSTOMIZED/GET_CUSTOMIZED_DATA_REQUEST', //请求获取定制数据的types
    // GET_CUSTOMIZED_DATA: 'CUSTOMIZED/GET_CUSTOMIZED_DATA', //成功获取定制数据的types

    
    SET_SELECTED_IMAGE_INDEX: 'CUSTOMIZED/SET_SELECTED_IMAGE_INDEX', //设置当前选中的定制底图的索引的types
    CHANGE_OPERATION_STATUS: 'CUSTOMIZED/CHANGE_OPERATION_STATUS', //改变operationArea视图显示的types
    CHANGE_IMAGE_CLASSIFY_ID: 'CUSTOMIZED/CHANGE_IMAGE_CLASSIFY_ID', //改变图片显示3大分类的types
    GET_CLASSIFY_IMAGE_REQUEST: 'CUSTOMIZED/GET_CLASSIFY_IMAGE_REQUEST',  //请求图片分类数据的types
    GET_CLASSIFY_IMAGE: 'CUSTOMIZED/GET_CLASSIFY_IMAGE', //成功获取图片分类的数据的types
    GET_IMAGE_LIST_REQUEST: 'CUSTOMIZED/GET_IMAGE_LIST_REQUEST', //请求系统分类图片的types
    GET_IMAGE_LIST: 'CUSTOMIZED/GET_IMAGE_LIST',    //成功获取系统分类图片的types
    
    GET_FONT_LIST: 'CUSTOMIZED/GET_FONT_LIST',  //成功获取字体数据的types
    CHANGE_INPUT_VALUE: 'CUSTOMIZED/CHANGE_INPUT_VALUE', //改变当前输入框字体内容的types
    SET_FONT_TYPE: 'CUSTOMIZED/SET_FONT_TYPE',  //改变当前选中的字体类别的types
    GET_FONT_LIST_REQUEST: 'CUSTOMIZED/GET_FONT_LIST_REQUEST',  //请求字体数据的types
    SET_FONT_INDEX: 'CUSTOMIZED/SET_FONT_INDEX',  //设置字体的索引
    SET_FONT_LOADING: 'CUSTOMIZED/SET_FONT_LOADING', //设置字体是否可以加载


    SET_CURRENT_IMAGE_ID: 'CUSTOMIZED/SET_CURRENT_IMAGE_ID', //设置当前选中的插图分类id
    SET_IMAGE_LOADING: 'CUSTOMIZED/SET_IMAGE_LOADING',  //设置插图是否可以加载
    SET_IMAGE_PAGE: 'CUSTOMIZED/SET_IMAGE_PAGE',  //设置当前所选分类插图的搜索页数

    SET_SHOW_LOGIN: 'CUSTOMIZED/SET_SHOW_LOGIN', //设置显示登录视图的types

}


//actions
export const actions = {
    //设置添加图片到canvas的方法
    setAddImageFn: fn => ({
        type: types.SET_ADD_IMAGE_FN,
        fn
    }),

    //设置添加图片到canvas的方法
    setAddFontFn: fn => ({
        type: types.SET_ADD_FONT_FN,
        fn
    }),

    //设置字体颜色的方法
    setChangeFontColorFn: fn => ({
        type: types.SET_CHANGE_FONT_COLOR_FN,
        fn
    }),
    // 设置当前操作的canvas
    setCanvasFn: fn => ({
        type: types.SET_CANVAS_FN,
        fn
    }),

    //设置计算canvas上元素是否超出绘制框的方法
    setCalculateOutlineFn: fn => ({
        type: types.SET_CALCULATE_OUTLINE_FN,
        fn
    }),

    //设置清空canvas的方法
    setClearCanvasFn: fn => ({
        type: types.SET_CLEAR_CANVAS_FN,
        fn
    }),

    //设置放大或缩小设计区域的方法
    setScaleFn: fn => ({
        type: types.SET_SCALE_FN,
        fn
    }),

    //设置克隆canvas上被选中的元素
    setCloneElementFn: fn => ({
        type:　types.SET_CLONE_ELEMENT_FN,
        fn
    }),

    //设置更新定制信息的方法
    setUpdateCustomizedDataFn: fn => ({
        type: types.SET_UPDATE_CUSTOMIZED_DATA_FN,
        fn
    }),



    //设置定制数据的方法
    setCustomizedData: (data,pid,userDesignId) => ({
        type: types.SET_CUSTOMIZED_DATA,
        data,
        pid,
        userDesignId
    }),

    //设置定制后的图片数据的方法
    setCustomizedImageData: (data,pid,userDesignId) => ({
        type: types.SET_CUSTOMIZED_IMAGE_DATA,
        data,
        pid,
        userDesignId
    }),

    //设置当前款式所选颜色的方法
    setOriginalColor: color => ({
        type: types.SET_ORIGINAL_COLOR,
        color
    }),

    //设置新的userDesignId的方法
    setNewUserDesignId: userDesignId => ({
        type: types.SET_NEW_USER_DESIGN_ID,
        userDesignId
    }),

    //请求获取计价规则的方法
    getPriceRuleRequest: () => ({
        type: types.GET_PRICE_RULE_REQUEST
    }),

    //请求获取定制数据的方法
    getCustomizedDataRequest: data => ({
        type: types.GET_CUSTOMIZED_DATA_REQUEST,
        data
    }),



    

    // 改变定制页面视图显示的actions
    changeOperationStatus: (status) => ({
        type: types.CHANGE_OPERATION_STATUS,
        status
    }),
    //改变图片显示3大分类的actions
    changeImageClassifyId: (id) => ({
        type: types.CHANGE_IMAGE_CLASSIFY_ID,
        id
    }),
    // 请求图片分类的数据的actions
    getClassifyImageRequest: () => ({
        type: types.GET_CLASSIFY_IMAGE_REQUEST
    }),
    // 请求系统分类图片的actions
    getImageListRequest: data => ({
        type: types.GET_IMAGE_LIST_REQUEST,
        data
    }),

    //请求字体数据的actions
    getFontListRequest: (path,data) => ({
        type: types.GET_FONT_LIST_REQUEST,
        path,
        data
    }),
    // 改变当前输入框的字体内容
    changeInputValue: value => ({
        type: types.CHANGE_INPUT_VALUE,
        value
    }),

    // 改变当前选中的字体类别
    setFontType: value => ({
        type: types.SET_FONT_TYPE,
        value
    }),
   
    // 设置当前选中的定制底图索引的方法
    setSelectedImageIndex: index => ({
        type: types.SET_SELECTED_IMAGE_INDEX,
        index
    }),


    // 设置字体的索引
    setFontIndex: (path,index) => ({
        type: types.SET_FONT_INDEX,
        path,
        index
    }),


    //设置字体是否可以加载
    setFontLoading: flag => ({
        type: types.SET_FONT_LOADING,
        flag
    }),


    //设置当前选中的插图分类id
    setCurrentImageId: id => ({
        type: types.SET_CURRENT_IMAGE_ID,
        id
    }),
   

    //设置插图是否可以加载
    setImageLoading: flag => ({
        type: types.SET_IMAGE_LOADING,
        flag
    }),

    // 设置当前所选分类插图的搜索页数
    setImagePage: (cateId,page) => ({
        type: types.SET_IMAGE_PAGE,
        cateId,
        page
    }),

    // 设置是否要登录的方法
    setShowLogin: showLogin => ({
        type: types.SET_SHOW_LOGIN,
        showLogin
    }),



}

//转换字体分类数据
const convertFontList = data => {
    if(!data) return Immutable.OrderedMap({})
    let fontData = Object.values(JSON.parse(data.split('@ImageFace:')[1]));
    let dataMap = {};
    fontData.forEach( item => {
        dataMap[item[1]] = {
            fontId: item[1],
            fontSize: 48,
            fontDpi: 1000,
            src: `data:image/png;base64,${item[6]}`
        };
    })
    return Immutable.OrderedMap(dataMap)
}

// 计算定制元素的最终总价
const calculateElementPrice = (data,priceRule) => {
    // console.log(priceRule.toJS())
    // console.log(data)
    let price = 0;
    let countFlag = 0;
    //最大免费的金额
    const maxPrice = priceRule.get('MaxPrice');
    //免费的数量
    const freeNum = priceRule.get('ValBase');
    
    data.forEach( v => {
        v.forEach( val => {
            if( (countFlag<freeNum) && ( Number(val.price) <= Number(maxPrice) ) ) {
                countFlag++;
            } else {
                price+= Number(val.price)
            }
            
        })
    });
    return price
}

//转换计价规则数据
const convertPriceRule = data => {
    // console.log(data)
    const {
        shopConfig,
        userPrice,
        fontPrice
    } = data
    let newData = {
        userPrice,
        fontPrice
    }
    Object.assign(newData,JSON.parse(shopConfig))
    return Immutable.fromJS(newData)
}

//reducer
const reducer = (state = initialState, action) => {
    switch(action.type) {
        // canvas上的相关方法--
        case types.SET_ADD_IMAGE_FN:
            return state.set('addImageFn',action.fn)
            
        case types.SET_ADD_FONT_FN:
            return state.set('addFontFn',action.fn)

        case types.SET_CHANGE_FONT_COLOR_FN:
            return state.set('changeFontColorFn',action.fn)
        
        case types.SET_CANVAS_FN:
            return state.set('getCanvas',action.fn)

        case types.SET_CALCULATE_OUTLINE_FN:
            return state.set('calculateOutlineFn',action.fn)

        case types.SET_CLEAR_CANVAS_FN:
            return state.set('clearCanvasFn',action.fn)

        case types.SET_SCALE_FN:
            return state.set('scaleFn',action.fn)
        
        case types.SET_CLONE_ELEMENT_FN:
            return state.set('cloneElementFn',action.fn)

        case types.SET_UPDATE_CUSTOMIZED_DATA_FN:
            return state.set('updateCustomizedDataFn',action.fn)
        // --canvas上的相关方法


        // 定制数据---
        case types.SET_CUSTOMIZED_DATA:
            // console.log(action.data.toJS())
            // var a = state.setIn(['customizedData',action.pid,action.userDesignId], action.data)
            //             .set('elementPrice',  calculateElementPrice(action.data))
            // console.log(a.get('elementPrice'))        
            return state.setIn(['customizedData',action.pid,action.userDesignId], action.data)
                        .set('elementPrice',  calculateElementPrice(action.data,state.get('priceRule')))

        case types.SET_CUSTOMIZED_IMAGE_DATA:
            return state.setIn(['customizedImageData',action.pid,action.userDesignId], action.data)

        case types.SET_ORIGINAL_COLOR:
           
            return state.set('originalColor',action.color)

        case types.SET_NEW_USER_DESIGN_ID:

            return state.set('newUserDesignId',action.userDesignId)

        case types.GET_PRICE_RULE:
            // console.log(action.priceRule)
            return state.set('priceRule',convertPriceRule(action.priceRule))
           
        // ---定制数据
            


        case types.CHANGE_OPERATION_STATUS:
            // console.log(action)
            return state.set('operationStatus',action.status)

        case types.CHANGE_IMAGE_CLASSIFY_ID:
            // console.log(action)
            return state.set('imageClassifyId',action.id)

        case types.GET_CLASSIFY_IMAGE:
            return state.set('classifyImage',Immutable.fromJS(action.data.system.chilendNode));

        case types.GET_IMAGE_LIST:
            // var a = state.setIn(['imageList',action.cateId],Immutable.OrderedSet([]))
                        //  .mergeIn(['imageList',action.cateId],Immutable.OrderedSet(action.data))
                        //  console.log(a.toJS())
            // var a = state.mergeIn(['imageList',action.cateId],Immutable.OrderedSet(action.data))
            // console.log(action.page)
            if(action.page === 1) {
                return state.setIn(['imageList',action.cateId],Immutable.OrderedSet([]))
                            .mergeIn(['imageList',action.cateId],Immutable.OrderedSet(action.data))
                            .setIn(['imagePage',action.cateId,'page'],action.page)
            } else {
                return state.mergeIn(['imageList',action.cateId],Immutable.OrderedSet(action.data))
            }


        case types.GET_FONT_LIST:
            // console.log(111)
            return state.mergeIn(['fontList',action.path],convertFontList(action.data))
        
        case types.CHANGE_INPUT_VALUE:
            return state.set('inputValue',action.value)

        case types.SET_FONT_TYPE:
           
            return state.set('currentFontType',Immutable.Map(action.value))

        case types.SET_SELECTED_IMAGE_INDEX:
            return state.set('selectedImageIndex',action.index)
        
        case types.SET_FONT_INDEX:
            // console.log(action)
            return state.setIn(['fontList',action.path],action.index)

        case types.SET_FONT_LOADING:
            return state.setIn(['fontList','fontLoading'],action.flag)

        case types.SET_CURRENT_IMAGE_ID:
            return state.set('currentImageId',action.id)

        case types.SET_IMAGE_LOADING:
            return state.set('imageLoading',action.flag)

        case types.SET_IMAGE_PAGE:
            return state.setIn(['imagePage',action.cateId,'page'],action.page)

        case types.SET_SHOW_LOGIN:
            return state.set('showLogin',action.showLogin)
        default:
            return state
    }

}

export default reducer

// selectors

// canvas上的相关方法---

//筛选出添加图片到canvas的方法
export const selectAddImageFn = state => state.getIn(['customized','addImageFn'])

// 筛选出添加字体到canvas的方法
export const selectAddFontFn = state => state.getIn(['customized','addFontFn'])

//筛选出改变字体颜色的方法
export const selectChangeFontColorFn = state => state.getIn(['customized','changeFontColorFn'])

//筛选出返回当前操作的canvas的方法
export const selectGetCanvas = state => state.getIn(['customized','getCanvas'])

// 筛选出计算canvas上元素是否超出绘制框的方法
export const selectCalculateOutlineFn = state => state.getIn(['customized','calculateOutlineFn'])

// 筛选出清空canvas的方法
export const selectClearCanvasFn = state => state.getIn(['customized','clearCanvasFn'])

//筛选出放大或缩小设计区域的方法
export const selectScaleFn = state => state.getIn(['customized','scaleFn'])

//筛选出克隆canvas上被选中元素的方法
export const selectCloneElementFn = state => state.getIn(['customized','cloneElementFn'])

// 筛选出更新定制信息的方法
export const selectUpdateCustomizedDataFn = state => state.getIn(['customized','updateCustomizedDataFn'])

// ---canvas上的相关方法



// 定制数据---

// 筛选出定制数据的方法
export const selectCustomizedData = (state,pid,userDesignId) => state.getIn(['customized','customizedData',pid,userDesignId])

// 筛选出定制后图片的方法
export const selectCustomizedImageData = (state,pid,userDesignId) => state.getIn(['customized','customizedImageData',pid,userDesignId])

//筛选出当前款式所选颜色
export const selectOriginalColor = state => state.getIn(['customized','originalColor'])

// 筛选出当前定制商品总价
export const selectElementPrice = state => state.getIn(['customized','elementPrice'])

// 筛选出新的userDesignId
export const selectNewUserDesignId = state => state.getIn(['customized','newUserDesignId'])

//筛选出计价规则
export const selectPriceRule = state => state.getIn(['customized','priceRule'])

// ---定制数据




// 筛选出当前operationArea显示的视图状态
export const selectOperationStatus = state => state.getIn(['customized','operationStatus'])

// 筛选imageClassifyId
export const selectImageClassifyId = state => state.getIn(['customized','imageClassifyId'])

//筛选系统图片分类数据
export const selectClassifyImage = state => state.getIn(['customized', 'classifyImage'])



//筛选出当前选中的插图分类id
export const selectCurrentImageId = state => state.getIn(['customized','currentImageId'])

// 筛选系统具体分类图片的数据
export const selectImageList = state => state.getIn(['customized','imageList',selectCurrentImageId(state)])


//筛选出当前选中的字体类别
export const selectCurrentFontType = state => state.getIn(['customized','currentFontType'])

//筛选出当前选中的字体类型的全部数据
export const selectFontList = state => state.getIn(['customized','fontList',selectCurrentFontType(state).get('path')])

//筛选当前输入框的字体内容
export const selectInputValue = state => state.getIn(['customized','inputValue'])

// 筛选出当前选中定制底图的索引
export const selectImageIndex = state => state.getIn(['customized','selectedImageIndex'])

//筛选出不同字体类型的索引
export const selectFontIndex = state => state.getIn(['customized','fontList',selectCurrentFontType(state).get('indexType')])

// 筛选出是否可以加载字体数据的标识
export const selectFontLoading = state => state.getIn(['customized','fontList','fontLoading'])

//筛选出是否可以加载插图数据的标识
export const selectImageLoading = state => state.getIn(['customized','imageLoading'])

// 筛选出当前分类插图已加载的页数
export const selectImagePage = state => state.getIn(['customized','imagePage',selectCurrentImageId(state),'page'])

//筛选出是否要登录的标识
export const selectShowLogin = state => state.getIn(['customized','showLogin'])