import React, { Component } from 'react';
import styles from './style.module.less'
import {
    Title,
    Content
} from './components'

class Favorite extends Component {


    render() {

        return (
            <div className={styles.body}>
                <div className={styles.center}>
                    <Title/>
                    <Content />
                </div>
            </div>
        );
    }
}

export default Favorite;