import ajax from './ajax'

//获取字体列表
export const getFontList = (path,data) => ajax(`${path}/`,data,'GET',true)

//根据fontId获取字体的svg字形
export const getSvgPath = data => ajax('svgpath/',data,'GET',true)


// 请求购物车数据
// {page: 1,limit: 7,TeamID: 0,type: 'personal'}
export const getCartInfo = data => ajax(`Shoppingcart/getCartGroup`,data)

//获取猜你喜欢数据
export const getFavoriteData = () => ajax('getFavorite')


// 获取具体商品数据的接口
export const getProductInfo = data => ajax('product/getBanxingList',data,'POST')

//获取图片分类数据
export const getClassifyImage = () => ajax('material/customizedmaterials')

//获取具体分类的图片列表
export const getImageList = data => ajax('material/getSyspic',data,'POST')


//获取款式分类的方法
export const getCategoryData = () => ajax('Api/getClass')

//根据款式id获取分类商品数据
export const getCategoryProductList = data => ajax('Api/getSingleClass',data)

// 获取首页轮播图数据
export const getSlideImage = () => ajax('Api/getSlideImg')

//上传定制数据及定制底图
export const sendCustomizedData = data => ajax('material/materialStatus',data,'POST')

// 加入购物车的接口
export const addToCart = data => ajax('Shoppingcart/JoinCartOrBuy',data,'POST')

//获取计价规则的接口
export const getPriceRule = () => ajax('Product/getValuationMethod')

//根据pid和UserDesignID获取定制数据接口
export const getCustomizedData = data => ajax('material/getMaterialStatus',data,'POST')

// 根据cartID删除购物车数据
export const deleteCart = data => ajax('Shoppingcart/delCart',data,'POST')

// 根据cartid获取订单数据
export const getCheckOrderData = data => ajax('shoppingcart/getCheckOrderData',data,'POST')

// 根据data(cartId)和vouchersid获取订单的总价
export const getOrderPrice = data => ajax('shoppingcart/getChooseOrderPriceToatl',data,'POST')

// 获取地址列表数据
export const getAddressList = () => ajax('member/getAddressList')

// 新增收货地址
export const createAddress = data => ajax('member/addAddress',data,'POST')

//设置默认地址的接口
export const setDefaultAddress = data => ajax('member/setDefault',data,'POST')

//删除地址的接口
export const deleteAddress = data => ajax('member/delAddress',data,'POST')

// 编辑地址的接口
export const editAddress = data => ajax('member/editAddress',data,'POST')

// 提交订单的接口
export const submitOrder = data => ajax('OrderPayPc/PayOrder',data)

//根据OrderID和PaymentID提交订单支付接口
export const submitOrderAgain = data => ajax('OrderPayPc/twoPay',data)

//获取商城配置信息
export const getMenuList = () => ajax('Index/getMenuList')

//根据OrderNO获取订单支付信息
export const getOrderPayByOrderNumber = data => ajax('OrderPayPc/NewPayOrder',data)

// 根据OrderNO获取订单是否支付完成
export const getPayStatus = data => ajax('Api/getPayStatus',data)

//获取订单数据列表的接口
export const getOrderList = data => ajax('order/get_order',data)

// 使用账户密码登录接口
export const accountLogin = data => ajax('Account/account_login',data,'POST')

//提醒发货接口
export const remindDeliver = data => ajax('Member/remindDelivery',data,'POST')

//确认收货接口
export const confirmReceive = data => ajax('order/receiving',data,'POST')

// 根据page,rows获取我的收藏数据
export const getMyCollection = data => ajax('Api/getMyCollection',data)

// 根据page,rows获取我的作品数据
export const getMyDesign = data => ajax('Api/getMyDesign',data)

// 获取体征列表
export const getBodyInfo = () => ajax('member/getBodyInfoList')

// 根据InfoID删除体征数据
export const deleteBodyInfo = data => ajax('Product/delMemberInfo',data,'POST')

//根据type,page,limit获取抵用券数据
export const getCouponData = data => ajax('member/get_vouchers',data)

//获取积分数据
export const getScoreData = () => ajax('member/get_score',{lastId:0})

//获取消息通知数据
export const getMessageData = () => ajax('member/get_letter',{lastId:0})

//获取账户信息的接口
export const getAccountInfo = () => ajax('member/getAccountInfo')

//保存账户信息
export const saveAccountInfo = data => ajax('member/saveInfo',data,'POST')

//获取短信验证码
export const getAuthCodeMessage = data => ajax('Account/sms',data,'POST')

//修改绑定的手机号
export const bindPhoneNumber = data => ajax('member/reviseMobile',data,'POST')

//修改登录密码
export const changePassword = data => ajax('member/revisePassword',data,'POST')

//设置用户头像的接口
export const setUserAvatar = data => ajax('member/cutHeadPhoto',data,'POST')

// 注册用户的接口
export const registerAccount = data => ajax('Api/register',data,'POST')

//发送短信验证码
export const getMessage = data => ajax('Account/sms',data,'POST')

//退出登录接口
export const logout = () => ajax('account/logout')