
import { 
    Home,
    Product,
    Cart,
    Category,
    Customized,
    CheckOrder,
    Payment,
    OrderList,
    ChoosePayType,
    Address,
    PersonalCenter,
    Message,
    AccountInfo,
    MyScore,
    MyCollection,
    MyDesign,
    BodyInfo,
    Coupon,
} from '../pages'
  
// 多层级路由
// const routes = [
//     {
//       path: "/sandwiches",
//       component: Sandwiches
//     },
//     {
//       path: "/tacos",
//       component: Tacos,
//       routes: [
//         {
//           path: "/tacos/bus",
//           component: Bus
//         },
//         {
//           path: "/tacos/cart",
//           component: Cart
//         }
//       ]
//     }
//   ];

const routes = [
    
    {
        pathname: '/',
        component: Home,
        exact: true
    },
   
    {
        pathname: '/product/:pid/:userDesignId',
        component: Product,
        exact: false
    },
    {
        pathname: '/cart',
        component: Cart,
        exact: false
    },
    {
        pathname: '/category/:id',
        component: Category,
        exact: false
    },

    {
        pathname: '/customized/:pid/:userDesignId',
        component: Customized,
        exact: false
    },

    {
        pathname: '/checkOrder/:cartId',
        component: CheckOrder,
        exact: false
    },
    
    {
        pathname: '/payment/:orderNumber',
        component: Payment,
        exact: false
    },

    {
        pathname: '/orderList',
        component: OrderList,
        exact: false
    },

    {
        pathname: '/choosePayType/:orderId',
        component: ChoosePayType,
        exact: false
    },

    {
        pathname: '/address',
        component: Address,
        exact: false
    },
    {
        pathname: '/personalCenter',
        component: PersonalCenter,
        exact: false
    },
    {
        pathname: '/message',
        component: Message,
        exact: false
    },
    {
        pathname: '/accountInfo',
        component: AccountInfo,
        exact: false
    },
    {
        pathname: '/myScore',
        component: MyScore,
        exact: false
    },
    {
        pathname: '/myCollection',
        component: MyCollection,
        exact: false
    },
    {
        pathname: '/myDesign',
        component: MyDesign,
        exact: false
    },
    {
        pathname: '/bodyInfo',
        component: BodyInfo,
        exact: false
    },
    {
        pathname: '/coupon',
        component: Coupon,
        exact: false
    },
];


export default routes
