import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'
class Paging extends Component {
    static propTypes = {
        lastPageFn: PropTypes.func.isRequired, //点击上一页执行的方法
        nextPageFn: PropTypes.func.isRequired, //点击下一页执行的方法
        page: PropTypes.number.isRequired,  //当前查询的页数
        total: PropTypes.number.isRequired, //当前分页总数
        limit: PropTypes.number.isRequired, //每页查询的条数
    }
    render() {
        const {
            lastPageFn,
            nextPageFn,
            page,
            total,
            limit
        } = this.props
        return (
            <div className={styles.body}>
                 <div className={styles.buttonArea}>
                    <div className={styles.lastPage} onClick={lastPageFn}>
                        <div 
                            className={styles.inner}
                            style={{
                                color: page === 1 ? '#ccc' : '#333'
                            }}
                        >
                            <div className={`iconfont icon-fanhuizuojiantouxiangzuoshangyibuxianxing ${styles.icon}`}></div>
                            <div className={styles.name}>上一页</div>
                        </div>
                    </div>
                    <div className={styles.nextPage} onClick={nextPageFn}>
                        <div 
                            className={styles.inner}
                            style={{
                                color: page < Math.ceil(total / limit) ? '#333' : '#ccc'
                            }}
                        >
                            <div className={styles.name}>下一页</div>
                            <div className={`iconfont icon-fanhuizuojiantouxiangzuoshangyibuxianxing1 ${styles.icon}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Paging;