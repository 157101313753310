import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class ImageArea extends Component {
    
    static propTypes = {
        index: PropTypes.number.isRequired,     //当前选中的图片索引
        data: PropTypes.array.isRequired,       //轮播的图片数据
    }
    render() {
        const {
                index,
                data
        } = this.props;

        return (
            <div className={styles.imageArea}>
                {
                    data.map( (item,idx) => 
                        <div
                            key={item.Image}
                            className={styles.imageItem}
                            style={{
                                background: `url(${item.Image}) center/cover no-repeat`,
                                opacity: idx === index ? 1 : 0,
                                zIndex: idx === index ? 2 : 0
                            }}
                            onClick={this.clickImage.bind(this,idx)}
                        ></div>    
                    )
                }
            </div>
        );
    }

    //点击图片触发事件
    clickImage = idx => {
        console.log(idx)
    }

}

export default ImageArea;