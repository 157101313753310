import { combineReducers} from 'redux-immutable'

import app from './app'
import home from './home'
import cart from './cart'
import product from './product'
import customized from './customized'
import address from './address'
import order from './order'
import myCollection from './myCollection'
import myDesign from './myDesign'
import bodyInfo from './bodyInfo'


const rootReducer = combineReducers({
    app,
    home,
    cart,
    product,
    customized,
    address,
    order,
    myCollection,
    myDesign,
    bodyInfo
});


export default rootReducer;



// complex selectors