import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class Bottom extends Component {
    static propTypes = {
        data: PropTypes.object,  //单个优惠券的信息
    }
    render() {
        const {
            currentId
        } = this.props
        return (
            <div 
                className={styles.bottom}
                style={{
                    background: currentId === 1 ? `url(${require('../../images/coupon/bottom.webp')}) center/contain no-repeat` : `url(${require('../../images/coupon/bottom2.webp')}) center/contain no-repeat`,
                    color: currentId === 1 ? '#dc776d' : '#bcbcbc'
                }}
            >
                <div className={styles.inner}>
                    <span>全场通用；券不可与其他优惠叠加使用。</span>
                </div>
            </div>
        );
    }
}

export default Bottom;