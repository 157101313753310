import Immutable from 'immutable'


//设置初始的state
const initialState = Immutable.fromJS({
    addressData: {},  //收货人地址数据
    selectedAddressId: null, //当前选中的收货人地址id
    editAddressId: null, //当前编辑地址的id
    formData: {}, //填写收货地址信息的表单
    showCreateAddress: false,  //设置是否显示新建收货人信息视图
    paymentId: null,  //当前选中的支付id
})


//types
export const types = {
    GET_ADDRESS_DATA_REQUEST: 'ADDRESS/GET_ADDRESS_DATA_REQUEST', //请求获取地址数据的types
    GET_ADDRESS_DATA: 'ADDRESS/GET_ADDRESS_DATA', //成功获取地址数据的types
    SET_SELECTED_ADDRESS_ID: 'ADDRESS/SET_SELECTED_ADDRESS_ID', //设置当前选中的地址id的types
    CREATE_ADDRESS_REQUEST: 'ADDRESS/CREATE_ADDRESS_REQUEST', //请求新增收货地址的types
    CREATE_ADDRESS: 'ADDRESS/CREATE_ADDRESS', //成功新增收货地址的types
    SET_DEFAULT_ADDRESS_REQUEST: 'ADDRESS/SET_DEFAULT_ADDRESS_REQUEST', //请求设置默认地址的types
    SET_DEFAULT_ADDRESS: 'ADDRESS/SET_DEFAULT_ADDRESS', //成功设置默认地址的types
    DELETE_ADDRESS_REQUEST: 'ADDRESS/DELETE_ADDRESS_REQUEST', //请求删除地址的types
    DELETE_ADDRESS: 'ADDRESS/DELETE_ADDRESS', //成功删除地址的types
    SET_FORM_DATA: 'ADDRESS/SET_FORM_DATA', //设置收货地址信息表单的types
    SET_SHOW_CREATE_ADDRESS: 'ADDRESS/SET_SHOW_CREATE_ADDRESS',  //设置是否显示新建收货人信息视图的types
    SET_EDIT_ADDRESS_ID: 'ADDRESS/SET_EDIT_ADDRESS_ID', //设置编辑地址的id的types
    EDIT_ADDRESS_REQUEST: 'ADDRESS/EDIT_ADDRESS_REQUEST', //请求编辑地址的types
    EDIT_ADDRESS: 'ADDRESS/EDIT_ADDRESS', //成功编辑地址的types
    SET_PAYMENT_ID: 'ADDRESS/SET_PAYMENT_ID', //设置当前支付id的types
}


//actions
export const actions = {
    // 请求获取地址数据
    getAddressDataRequest: () => ({
        type: types.GET_ADDRESS_DATA_REQUEST
    }),
    // 设置当前选中的地址id
    setSelectedAddressId: id => ({
        type: types.SET_SELECTED_ADDRESS_ID,
        id
    }),
    // 请求新增收货地址的方法
    createAddressRequest: data => ({
        type: types.CREATE_ADDRESS_REQUEST,
        data
    }),

    //请求设置默认地址
    setDefaultAddressRequest: addressId => ({
        type: types.SET_DEFAULT_ADDRESS_REQUEST,
        addressId
    }),

    // 删除地址的请求
    deleteAddressRequest: addressId => ({
        type: types.DELETE_ADDRESS_REQUEST,
        addressId
    }),
    
    //设置当前编辑地址的id
    setEditAddressId: addressId => ({
        type: types.SET_EDIT_ADDRESS_ID,
        addressId
    }),

    // 设置收货地址信息表单的方法
    setFormData: formData => ({
        type: types.SET_FORM_DATA,
        formData
    }),

    //设置是否显示新建收货人信息视图
    setShowCreateAddress: flag => ({
        type: types.SET_SHOW_CREATE_ADDRESS,
        flag
    }),

    //请求编辑收货人信息的方法
    editAddressRequest: data => ({
        type: types.EDIT_ADDRESS_REQUEST, 
        data
    }),

    //设置支付id的方法
    setPaymentId: paymentId => ({
        type: types.SET_PAYMENT_ID,
        paymentId
    }),


}

//转化地址数据
const convertAddressData = data => {
    let map = {}
    data.list.forEach( item => {
        map[item.AddressID] = item
    })
    return map

}

//转化新增收货人信息的数据
const convertCreateAddress = data => {
    const {
        addressId,
        data:{
            ContactPerson,
            Mobile,
            ProvinceName,
            CityName,
            AreaName,
            Address,
            Latitude,
            Longitude,
            AreaCode,
        }
    } = data
    // console.log(data)
    let dataMap = {}
    dataMap[addressId] = {
        ContactPerson,
        Mobile,
        ProvinceName,
        CityName,
        AreaName,
        Address,
        Latitude,
        Longitude,
        AreaCode,
        AddressID:addressId,
        IsDefault: '0'
    }
    return dataMap
}

// 把地址数据转化为表单数据
const convertFormData = data => {
    const {
        ContactPerson,
        Mobile,
        ProvinceName,
        CityName,
        AreaName,
        Address,
        AreaCode
    } = data
    let areaCode = JSON.parse(AreaCode)
    let addressData = {
        provinceCode: areaCode[0],
        provinceName: ProvinceName,
        cityCode: areaCode[1],
        cityName: CityName,
        districtCode: areaCode[2],
        districtName: AreaName ,
        contactPerson: ContactPerson,
        address: Address,
        phone: Mobile,
    }
    return Immutable.fromJS(addressData)
}

//reducer
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case types.GET_ADDRESS_DATA:
            return state.mergeIn(['addressData'],convertAddressData(action.data))

        case types.SET_SELECTED_ADDRESS_ID:
            return state.set('selectedAddressId',action.id)

        case types.CREATE_ADDRESS:
            return state.mergeIn(['addressData'],convertCreateAddress(action))
                        .set('selectedAddressId',action.addressId)

        case types.SET_DEFAULT_ADDRESS:
            return state.update('addressData', v => v.map( item => Immutable.fromJS(item).set('IsDefault','0').toJS() ))
                        .setIn(['addressData',action.addressId,'IsDefault'],'1')
        
        case types.DELETE_ADDRESS:
            return state.deleteIn(['addressData',action.addressId])

        case types.SET_EDIT_ADDRESS_ID:
                return state.set('editAddressId',action.addressId)
                            .set('formData', action.addressId ? convertFormData(state.getIn(['addressData',action.addressId])) : Immutable.fromJS({}))
                            .set('showCreateAddress',true)
           
        case types.SET_FORM_DATA:
            return state.set('formData',action.formData)

        case types.SET_SHOW_CREATE_ADDRESS:
            return state.set('showCreateAddress',action.flag)

        case types.EDIT_ADDRESS:
            
            return state.mergeIn(['addressData',action.data.AddressID],action.data)
                        .set('showCreateAddress',false)
        
        case types.SET_PAYMENT_ID:
            return state.set('paymentId',action.paymentId)
        
        default:
            return state
    }
}


export default reducer




// selectors

// 筛选出地址数据
export const selectAddressData = state => state.getIn(['address','addressData'])

//筛选出当前选中的地址id
export const selectSelectedAddressId = state => state.getIn(['address','selectedAddressId'])

//筛选出当前选中的地址数据
export const selectSelectedAddressData = state => state.getIn(['address','addressData',state.getIn(['address','selectedAddressId'])])

// 筛选出当前编辑地址的id
export const selectEditAddressId = state => state.getIn(['address','editAddressId'])

//筛选出收获地址信息表单的数据
export const selectFormData = state => state.getIn(['address','formData'])

// 筛选出是否显示新建收货人信息
export const selectShowCreateAddress = state => state.getIn(['address','showCreateAddress'])

// 筛选出当前选中的支付id
export const selectPaymentId = state => state.getIn(['address','paymentId'])