import React, { Component } from 'react';
import styles from './style.module.less'
import {
    Title,
    Content
} from './components'

import t1 from '../../images/t01.png'
import t2 from '../../images/t02.png'
import t3 from '../../images/t03.png'
import t4 from '../../images/t04.png'
import t5 from '../../images/t05.png'
import t6 from '../../images/t06.webp'
import t7 from '../../images/t07.webp'
import t8 from '../../images/t08.webp'
import t9 from '../../images/t09.webp'

const themeData = [
    [
        {src: t6,title: '赖龙辉'},
        {title: 'Funny232',src: t7},
        {title: 'Funny22',src: t8},
        {title: 'Funny313132',src: t9},
    ],
    [
        {title: 'Funny222',src: t1},
        {title: 'Fun222ny',src: t2},
        {title: 'Funny23',src: t3},
    ],
    [
        {title: 'Funny33323',src: t4},
        {title: 'Funny323',src: t5},
    ],
    [
        {title: 'Funny33323',src: t4},
        {title: 'Funny323',src: t5},
        {title: 'Funny343423',src: t5},
        {title: 'Funny3434323',src: t5},
    ],
    [
        {title: 'Funny33323',src: t4},
        {title: 'Funny323',src: t5},
    ],
]

class ThemeArea extends Component {
    render() {
        return (
            <div className={styles.body}>
                <div className={styles.center}>
                    <Title />
                    <Content 
                        data={themeData}
                    />
                </div>
            </div>
        );
    }
}

export default ThemeArea;