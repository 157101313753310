import React, { Component } from 'react';
import styles from './style.module.less'
import { withRouter } from 'react-router-dom';


@withRouter
class TabItem extends Component {
    state = {
        titleLeft: 40,
        color: 'rgb(46, 137, 128)'
    }
    render() {
        const {data} = this.props
        const {
            titleLeft,
            color
        } = this.state
        // console.log(data)
        return (
            <div 
                className={`${styles.tabItem} `} 
                onMouseOver={this.enter.bind(this,-60,'rgb(46,173,162)')}
                onMouseLeave={this.enter.bind(this,40,'rgb(46, 137, 128)')}
                onClick={this.enterPage}
            >   
                <div 
                    className={`${styles.icon} iconfont ${data.icon}`} 
                    style={{
                        background:color
                    }}
                ></div>
                <div 
                    className={styles.title}
                    style={{
                        left: titleLeft,
                        background:color
                    }}
                >{data.name}</div>
            </div>
        );
    }
    //设置标题是否显示
    enter = (titleLeft,color) => this.setState({titleLeft,color})

    //进入侧边栏选中的页面
    enterPage = () => {
        const {
            data:{path},
            history:{push}
        } = this.props
        push(path)
    }
}

export default TabItem;