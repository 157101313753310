import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class AddressInput extends Component {
    static propTypes = {
        formData: PropTypes.object, //当前表单的数据
    }
    render() {
        const {
            formData
        } = this.props
        return (
            <div className={styles.addressInput}>
                <div className={styles.title}>
                    {   
                        formData.get('provinceName')
                        ?
                        `${formData.get('provinceName')}${formData.get('cityName') ? `/${formData.get('cityName')}` : ''}${formData.get('districtName') ? `/${formData.get('districtName')}` :''}`
                        :
                        '--请选择--'
                    }
                    <div className={`iconfont icon-arrow-up1 ${styles.icon}`}></div>
                </div>
            </div>
        );
    }
}

export default AddressInput;