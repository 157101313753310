import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'

const loginData = [
    {
        name: '微信登录',
        id: 1
    },
    {
        name: '账号登录',
        id: 2
    }
]

class Top extends Component {
    static propTypes = {
        loginType: PropTypes.number,  //当前登录的方式
        setLoginType: PropTypes.func, //修改登录方式的方法
    }
    render() {
        const {
            loginType,
            setLoginType,
        } = this.props
        return (
            <div className={styles.top}>
                {
                    loginData.map( item => 
                        <div
                            key={item.id}
                            onClick={setLoginType.bind(this,item.id)}
                            className={styles.topItem}
                            style={{
                                color: item.id === loginType ? 'rgb(44,176,163)' : 'black'
                            }}
                        >{item.name}</div>    
                    )
                }
            </div>
        );
    }
}

export default Top;