import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class Top extends Component {
    static propTypes = {
        data: PropTypes.object,  //单个优惠券的信息
    }
    render() {
        const {
            data:{
                FaceValue,
                StartUsePrice,
                StartTime,
                EndTime,
                ID,
                ActivityName
            },
            currentId
        } = this.props
        // console.log(this.props.data)
        return (
            <div 
                className={styles.top}
                style={{
                    background: currentId === 1 ? `url(${require('../../images/coupon/top.webp')}) center/cover no-repeat` : `url(${require('../../images/coupon/top2.webp')}) center/cover no-repeat`
                }}
            >
                <div className={styles.faceValue} >
                    <span>{Number(FaceValue).toFixed(0)}</span>
                    <span className={styles.unit}>元</span>
                </div>
                <div className={styles.couponInfo}>
                    {/* <div className={styles.couponName}>{ActivityName}</div> */}
                    <div className={styles.discount}>{StartUsePrice}减{FaceValue}</div>
                    <div className={styles.time}>{StartTime.split(' ')[0]}-{EndTime.split(' ')[0]}</div>
                </div>
            </div>
        );
    }
}

export default Top;