import React, { Component } from 'react';
import styles from './style.module.less'
import Top from './Top'
import Bottom from './Bottom'
import WeChatLogin from './WeChatLogin'
import AccountLogin from './AccountLogin'

class LoginItem extends Component {
    state = {
        loginType: 1, //1表示微信登录，2表示账号登录
    }
    render() {
        const {
            loginType
        } = this.state
        return (
            <div className={styles.body}>
                <Top 
                    {...this.state}
                    setLoginType={this.setLoginType}
                />
                {
                    loginType === 1
                    ?
                    <WeChatLogin />
                    :
                    <AccountLogin />
                }
                <Bottom {...this.props} />
            </div>
        );
    }

    //修改登录的方式
    setLoginType = loginType => this.setState({loginType})

}

export default LoginItem;