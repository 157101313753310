import React, { Component } from 'react';
import styles from './style.module.less'
import AddressInput from './AddressInput';
import ShowAddressArea from './ShowAddressArea';
import PropTypes from 'prop-types'

class AddressCascade extends Component {
    static propTypes = {
        changeFormData: PropTypes.func, //修改formData数据的方法
        formData: PropTypes.object, //当前表单的数据
    }
    state = {
        showAddressArea: false,
        chooseTab: 1,
    }
    render() {
        const {
            showAddressArea
        } = this.state
        return (
            <div className={styles.body} onMouseOver={this.mouseOverFn} onMouseLeave={this.mouseLeaveFn}>
                <AddressInput 
                    {...this.props}
                />
                {
                    showAddressArea
                    ?
                    <ShowAddressArea 
                        {...this.state}
                        {...this.props}
                        setShowAddressArea={this.setShowAddressArea}
                        setProvinceCode={this.setProvinceCode}
                        setCityCode={this.setCityCode}
                        setDistrictCode={this.setDistrictCode}
                        setChooseTab={this.setChooseTab}
                    />
                    :
                    ''
                }
            </div>
        );
    }

      //设置省级行政单位代码
    setProvinceCode = (provinceCode,provinceName) => {
        const {
            formData,
            setFormData
        } = this.props
        this.setState({ chooseTab:2})
        let data = formData.set('provinceCode',provinceCode)
                           .set('provinceName',provinceName)
                           .set('cityCode',null)
                           .set('cityName',null)
                           .set('districtCode',null)
                           .set('districtName',null)
        setFormData(data)

    }
    
    //设置市级行政单位代码
    setCityCode = (cityCode,cityName) => {
        const {
            formData,
            setFormData
        } = this.props
        this.setState({chooseTab: 3})
        let data = formData.set('cityCode',cityCode)
                           .set('cityName',cityName)
                           .set('districtCode',null)
                           .set('districtName',null)
        setFormData(data)
    }

    // 设置区级行政单位代码
    setDistrictCode = (districtCode,districtName) => {
        const {
            formData,
            setFormData
        } = this.props
        let data = formData.set('districtCode',districtCode)
                           .set('districtName',districtName)
        setFormData(data)
        this.setShowAddressArea(false)
    } 

    //设置当前选中的行政级别
    setChooseTab = chooseTab => this.setState({chooseTab})

    //设置是否显示地址选择界面
    setShowAddressArea = showAddressArea => this.setState({showAddressArea})

    //鼠标进入事件
    mouseOverFn = () => this.setShowAddressArea(true)

    //鼠标移出事件
    mouseLeaveFn = () => this.setShowAddressArea(false)

}

export default AddressCascade;