import React, { Component } from 'react';
import {getFavoriteData} from '../../../../api'
import styles from './style.module.less'
import MoveButton from './MoveButton'
import ContentArea from './ContentArea'

class Content extends Component {
    constructor() {
        super();
        this.state = {
            goodsList: [],
            index: 0
        }
    }
    componentDidMount() {
        getFavoriteData().then( res => {
            this.setState({
                goodsList: res
            })
        })
    }
    render() {
        const {
            goodsList,
            index
        } = this.state;
        // console.log(goodsList)
        return (
            <div className={styles.body}>
                <MoveButton 
                    type='left'
                    index={index}
                    setIndex={this.setIndex}
                    maxIndex={Math.ceil(goodsList.length/4)}
                />
                <ContentArea 
                    data={goodsList}
                    index={index}
                />
                <MoveButton 
                    type='right'
                    index={index}
                    setIndex={this.setIndex}
                    maxIndex={Math.ceil(goodsList.length/4)}

                />
            </div>
        );
    }

    // 设置猜你喜欢的位移
    setIndex = index => {
        this.setState({index})
    }
}

export default Content;