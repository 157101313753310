import Immutable from 'immutable'

// 设置初始的state
const initialState = Immutable.fromJS({
    collectionData: {},
    collectionList: Immutable.OrderedSet([]),
    page: 1,
    total: 0,
})

// types
export const types = {
    GET_COLLECTION_DATA_REQUEST: 'MY_COLLECTION/GET_COLLECTION_DATA_REQUEST', //请求获取我的收藏数据的types
    GET_COLLECTION_DATA: 'MY_COLLECTION/GET_COLLECTION_DATA',  //成功获取我的收藏数据的types
    SET_PAGE: 'MY_COLLECTION/SET_PAGE', //设置当前查询的页数的types
    SET_TOTAL: 'MY_COLLECTION/SET_TOTAL', //设置我的收藏数据的总条数
}



// actions
export const actions = {
    //请求获取我的收藏数据
    getCollectionDataRequest: (page,rows) => ({
        type: types.GET_COLLECTION_DATA_REQUEST,
        page,
        rows
    }),
    // 设置当前查询的页数
    setPage: page => ({
        type: types.SET_PAGE,
        page
    })

}

//转化我的收藏数据
const convertCollectionData = data => {
    let dataMap = {}
    let dataList = []
    // console.log(data)
    data.forEach( item => {
        dataList.push(item.CollectionId)
        let data = item.productInfo;
        data.UserDesignID = item.UserDesignID
        data.CollectionId = item.CollectionId
        dataMap[item.CollectionId] = data
    })
    return {
        dataMap,
        dataList
    }
}

// reducer
const reducer = (state = initialState,action) => {
    switch(action.type) {
        case types.GET_COLLECTION_DATA:
       
            return state.setIn(['orderMap'],Immutable.fromJS({}))
                        .setIn(['orderList'],Immutable.OrderedSet([]))
                        .setIn(['collectionData'],convertCollectionData(action.data.data).dataMap)
                        .setIn(['collectionList'],convertCollectionData(action.data.data).dataList)

        case types.SET_PAGE:
            return state.set('page',action.page)
        
        case types.SET_TOTAL:
            return state.set('total',action.total)

        default: 
            return state
    }
}


export default reducer


//selectors

// 筛选出当前查询的页数
export const selectPage = state => state.getIn(['myCollection','page'])

// 筛选出当前收藏数据的有序数组
export const selectMyCollectionList = state => state.getIn(['myCollection','collectionList'])

// 筛选出当前页的收藏数据
export const selectMyCollectionData = state => {
    let data = Immutable.List([]);
    selectMyCollectionList(state).forEach( item => {
        let collectionItem = state.getIn(['myCollection','collectionData',item])
        if(collectionItem) {
            data = data.merge(collectionItem)
        }
    })
    return data
}

//筛选出当前收藏数据的总条数
export const selectTotal = state => state.getIn(['myCollection','total'])