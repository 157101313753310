import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'
import ThemeItem from './ThemeItem'

class Theme extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,   //一行主题的数据
    }
    render() {
        const {
            data
        } = this.props;
        // console.log(data)
        return (
            <div className={styles.theme}>
                {
                    data.map( item => 
                        <ThemeItem 
                            key={item.title}
                            data={item}
                        />    
                    )
                }
            </div>
        );
    }
}

export default Theme;