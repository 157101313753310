import React, { Component } from 'react';
import styles from './style.module.less'
import { withRouter } from 'react-router-dom';
import { logout } from '../../../../api';
import { connect } from 'react-redux';
import { selectUserInfo } from '../../../../redux/modules/home';

const mapState = state => ({
    userInfo: selectUserInfo(state)
})

@withRouter
@connect(mapState)
class Header extends Component {
    state = {
        showMore: false
    }
    render() {
        const {
            userInfo
        } = this.props
        const {
            showMore
        } = this.state
        console.log(userInfo)
        return (
            <div className={styles.body}>
                <div className={styles.inner}>
                    <div className={styles.leftArea} onClick={this.goToHome}>
                        <span className={`iconfont icon-home ${styles.icon}`}></span>
                        <span>首页</span>
                    </div>
                    <div className={styles.rightArea}>
                        <div className={`iconfont icon-pindaoliebiao ${styles.item} ${styles.channel}`}></div>
                        <div className={`iconfont icon-xiaoxi ${styles.item}`} onClick={this.goToMessage}></div>
                        <div 
                            className={`${userInfo&&userInfo.Avatar ? '' : 'iconfont icon-zhanghu'} ${styles.item} ${styles.account}`}
                            onMouseOver={this.mouseOverFn}
                            onMouseLeave={this.mouseLeaveFn}
                           
                        >   
                            {
                                userInfo && userInfo.Avatar
                                ?
                                <div
                                    style={{
                                        background: `url(${userInfo.Avatar}) center/contain no-repeat`
                                    }}
                                    className={styles.avatar}
                                ></div>
                                :
                                ''
                            }
                        {
                            showMore
                            ?
                            <div className={styles.accountTab}>
                                <div className={styles.name}>{userInfo && (userInfo.NickName ? userInfo.NickName : '暂无昵称') }</div>
                                <div onClick={this.goToAccount}>账户管理</div>
                                <div onClick={this.logoutFn}>退出登录</div>
                            </div>
                            :
                            ''
                        }
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    //切换是否显示账户详情的方法
    setShowMore = showMore => this.setState({showMore})

    //鼠标进入事件
    mouseOverFn = () => {
        this.setShowMore(true)
    }
    //鼠标离开事件
    mouseLeaveFn = () => {
        this.setShowMore(false)
    }
    //回到首页
    goToHome = () => {
        const {history:{push}} = this.props
        push('/')
    }

    //进入消息页面
    goToMessage = () => {
        const {history:{push}} = this.props
        push('/message')
    }

    //进入账户信息页面
    goToAccount = () => {
        const {history:{push}} = this.props
        push('/accountInfo')
    }

    //退出登录的方法
    logoutFn = () => {
        logout().then(res => {
            if(res.status === 1) {
                alert('退出登录成功')
                window.location.reload()
            }
        })
    }
}

export default Header;