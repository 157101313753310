import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

@withRouter
class Top extends Component {

    static propTypes = {
        src: PropTypes.string.isRequired,   //图片地址
        pid: PropTypes.string.isRequired,   //商品id
        userDesignId: PropTypes.string.isRequired, //商品设计id
    }
    render() {
        const {
            src,
        } = this.props;
        return (
            <div 
                className={styles.top} 
                style={{background: `url(${src}) center/contain no-repeat`}}
                onClick={this.goToProductPage}
            ></div>
        );
    }

    goToProductPage = () => {
        const {
            history:{push},
            pid,
            userDesignId
        } = this.props
        push(`/product/${pid}/${userDesignId}`)
    }
}

export default Top;