import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'

class ThemeItem extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,   //主题部件的数据
    }
    render() {
        const {
            data,
        } = this.props;
        return (
            <div className={styles.themeItem} >
                <div className={styles.imgDiv}>
                    <img src={data.src} alt='' />
                </div>
                <div className={styles.title}>{data.title}</div>
            </div>
        );
    }
}

export default ThemeItem;