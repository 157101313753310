import Immutable from 'immutable'

//设置初始的state
const initialState = Immutable.fromJS({
    orderMap: {},
    orderList: Immutable.OrderedSet([]),
    total: 0, 
    page: 1,
})

//types
export const types = {
    GET_ORDER_LIST_REQUEST: 'ORDER/GET_ORDER_LIST_REQUEST', //请求获取订单数据的types
    GET_ORDER_LIST: 'ORDER/GET_ORDER_LIST', //成功获取订单数据的types
    SET_TOTAL_ORDER: 'ORDER/SET_TOTAL_ORDER', //设置当前类型的订单总数的types
    SET_ORDER_PAGE: 'ORDER/SET_ORDER_PAGE', //设置当前页数的types
}

// actions
export const actions = {
    //请求获取订单数据列表
    getOrderListRequest: data => ({
        type: types.GET_ORDER_LIST_REQUEST,
        data
    }),

    //设置当前订单的查询页数
    setOrderPage: page => ({
        type: types.SET_ORDER_PAGE,
        page
    }),

    
}


// 转换订单数据
const convertOrderData = data => {
    let orderMap = {}
    let orderList = []
    data.forEach( item => {
        orderMap[item.OrderID] = item
        orderList.push(item.OrderID)
    })

    return {
        orderMap,
        orderList,
    }

}

// reducer
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case types.GET_ORDER_LIST:
            return state.setIn(['orderMap'],Immutable.fromJS({}))
                        .setIn(['orderList'],Immutable.OrderedSet([]))
                        .mergeIn(['orderMap'],convertOrderData(action.data).orderMap)
                        .mergeIn(['orderList'],convertOrderData(action.data).orderList)
        case types.SET_TOTAL_ORDER:
            return state.set('total',action.total)

        case types.SET_ORDER_PAGE:
            return state.set('page',action.page)

        default: 
            return state
    }
}

export default reducer

// selector

// 筛选出订单数据
export const selectOrderData = state => {
    let data = Immutable.List([])
    state.getIn(['order','orderList']).forEach( item => {
        let orderItem =  state.getIn(['order','orderMap',item])
        if(orderItem) {
            data = data.push(orderItem)
        }
    })
    return data
}

// 筛选出当前订单类型的总数
export const selectTotal = state => state.getIn(['order','total'])

//筛选出当前查询的页数
export const selectPage = state => state.getIn(['order','page'])