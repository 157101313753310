import axios from 'axios'
import Qs from 'qs'
// 0.定义基础路径
// const BASE_URL = 'http://localhost:8888/'
const BASE_URL = '/mobile/'
// const BASE_URL = 'http://mall.ushirt.info/mobile/'
const FONT_URL = 'https://ushirt.info:59999/'


const service = axios.create({
     baseURL: '',
     headers:{
        	"Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
        }
});


//request拦截器处理
service.interceptors.request.use( (config) => {
   
    return config;
});



// response拦截器处理
service.interceptors.response.use( res => {
    if(res.status === 200) {
        return res.data;
    } else {
        //全局处理错误
        console.log('出错了！！!');
        console.log(res)
    }

});


const ajax = (url = '', params = {}, type = 'GET',isFont) => {
    let promise;

    // 1.返回promise对象
    return new Promise( (resolve,reject) => {
        // 1.1判断请求的方式
        if( 'GET' === type.toUpperCase()){
            // 1.2拼接字符串
            let paramsStr = '';
            Object.keys(params).forEach( key => {
                paramsStr += key + '=' + params[key] + '&';
            })

            // 1.3过滤最后的&
            if(paramsStr !== '') {
                paramsStr = paramsStr.substr(0,paramsStr.lastIndexOf('&'))
            }

            // 1.4拼接完整的路径
            url += '?' + paramsStr;

            // 1.5发起get请求
            promise = service.get((isFont ? FONT_URL : BASE_URL) + url)
            // console.log((isFont ? FONT_URL : BASE_URL) + url)

        } else if('POST' === type.toUpperCase()) {

            promise = service.post((isFont ? FONT_URL : BASE_URL) + url,Qs.stringify(params));
              
        }

        // 1.6返回结果
        promise.then( response => {
            // console.log(response)
            resolve(response)
        }).catch( error => {
            reject(error)
        })

    })
}

export default ajax;