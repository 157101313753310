import Immutable from 'immutable'

// 设置初始的state
const initialState = Immutable.fromJS({
    myDesignData: {},
    myDesignList: Immutable.OrderedSet([]),
    page: 1,
    total: 0,
})



// types
export const types = {
    GET_MY_DESIGN_DATA_REQUEST: 'MY_DESIGN/GET_MY_DESIGN_DATA_REQUEST', //请求获取我的收藏数据的types
    GET_MY_DESIGN_DATA: 'MY_DESIGN/GET_MY_DESIGN_DATA',  //成功获取我的收藏数据的types
    SET_PAGE: 'MY_DESIGN/SET_PAGE', //设置当前查询的页数的types
    SET_TOTAL: 'MY_DESIGN/SET_TOTAL', //设置我的设计数据的总条数
}



// actions
export const actions = {
    //请求获取我的收藏数据
    getMyDesignDataRequest: (page,rows) => ({
        type: types.GET_MY_DESIGN_DATA_REQUEST,
        page,
        rows
    }),
    // 设置当前查询的页数
    setPage: page => ({
        type: types.SET_PAGE,
        page
    }),


}

//转化我的设计数据
const convertMyDesignData = data => {
    let dataMap = {}
    let dataList = []
    // console.log(data)
    data.forEach( item => {
        dataList.push(item.ProductID+item.UserDesignID)
        dataMap[item.ProductID+item.UserDesignID] = item
    })
    return {
        dataMap,
        dataList
    }
}

// reducer
const reducer = (state = initialState,action) => {
    switch(action.type) {
        case types.GET_MY_DESIGN_DATA:
        
            return state.setIn(['myDesignData'],Immutable.fromJS({}))
                        .setIn(['myDesignList'],Immutable.OrderedSet([]))
                        .setIn(['myDesignData'],convertMyDesignData(action.data.data).dataMap)
                        .setIn(['myDesignList'],convertMyDesignData(action.data.data).dataList)

        case types.SET_PAGE:
            return state.set('page',action.page)

        case types.SET_TOTAL:
            return state.set('total',action.total)

        default: 
            return state
    }
}


export default reducer


//selectors

// 筛选出当前查询的页数
export const selectPage = state => state.getIn(['myDesign','page'])

// 筛选出我的作品数据的有序数组
export const selectMyDesignList = state => state.getIn(['myDesign','myDesignList'])

// 筛选出当前页的作品数据
export const selectMyDesignData= state => {
    let data = Immutable.List([]);
    selectMyDesignList(state).forEach( item => {
        let collectionItem = state.getIn(['myDesign','myDesignData',item])
        if(collectionItem) {
            data = data.merge(collectionItem)
        }
    })
    return data
}

//筛选出我的设计数据的总条数
export const selectTotal = state => state.getIn(['myDesign','total'])