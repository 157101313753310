import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class TechItem extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,   //显示制作工艺的数据
    }
    render() {
        const {data} = this.props;
        // console.log(data)
        return (
            <li className={styles.techItem}>
                <div className={styles.info}>
                    <div className={styles.img} style={{background: `url(${data.src}) center/cover no-repeat`}}></div>
                    <div className={styles.right}>
                        <h4 className={styles.title}>{data.title}</h4>
                        <p>{data.describe}</p>
                    </div>
                </div>
            </li>
        );
    }
}

export default TechItem;