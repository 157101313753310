import Immutable from 'immutable'


//设置初始的state
const initialState = Immutable.fromJS( {
     categoryData: [],
     categoryProductList:{},
     shopConfig:null,
     paymentInfo: null,  //生成的订单信息

})

export const types = {
   GET_CATEGORY_DATA_REQUEST: 'HOME/GET_CATEGORY_DATA_REQUEST',  //获取款式分类数据请求的types
   GET_CATEGORY_DATA: 'HOME/GET_CATEGORY_DATA',  //成功获取款式分类数据的types
   GET_SLIDE_IMAGE_REQUEST: 'HOME/GET_SLIDE_IMAGE_REQUEST', //获取首页轮播图的请求的types
   GET_SLIDE_IMAGE: 'HOME/GET_SLIDE_IMAGE', //成功获取首页轮播图的types
   GET_CATEGORY_PRODUCT_LIST_REQUEST: 'HOME/GET_CATEGORY_PRODUCT_LIST_REQUEST', //根据id获取款式分类数据请求的types
   GET_CATEGORY_PRODUCT_LIST: 'HOME/GET_CATEGORY_PRODUCT_LIST',  //成功获取款式分类数据的types
   GET_SHOP_CONFIG_REQUEST: 'HOME/GET_SHOP_CONFIG_REQUEST', //请求获取商城配置信息的types
   GET_SHOP_CONFIG: 'HOME/GET_SHOP_CONFIG', //成功获取商城信息的types
   SET_PAYMENT_INFO: 'HOME/SET_PAYMENT_INFO', //设置生成订单信息的types
   SET_PAGE: 'HOME/SET_PAYMENT_INFO',   //设置款式分类的页数的types
}


export const actions = {

  //请求款式分类数据的方法
   getCategoryDataRequest: () => ({
     type: types.GET_CATEGORY_DATA_REQUEST
   }),
 
   //根据id获取该款式的商品数据
   getCategoryProductListRequest: data => ({
      type: types.GET_CATEGORY_PRODUCT_LIST_REQUEST,
      data
  }),

   //请求首页轮播图的方法
   getSlideImageRequest: () => ({
     type: types.GET_SLIDE_IMAGE_REQUEST
   }),

  //  请求获取商城配置信息
   getShopConfigRequest: () => ({
      type: types.GET_SHOP_CONFIG_REQUEST
   }),

  //设置生成订单的types
  setPaymentInfo: data => ({
      type: types.SET_PAYMENT_INFO,
      data
  }),

  //根据id设置商品分类页数
  setPage: (id,page) => ({
     type: types.SET_PAGE,
     id,
     page
  })

}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_CATEGORY_DATA:
        return state.set('categoryData',action.data[0].children)

      case types.GET_CATEGORY_PRODUCT_LIST:
        // console.log(action)
        // if(action.page === 1) {
          // return state.setIn(['categoryProductList',action.pid,'data'],Immutable.OrderedSet([]))
          return state.setIn(['categoryProductList',action.pid,'data'],action.data)
                      .setIn(['categoryProductList',action.pid,'total'],action.total)
                      .setIn(['categoryProductList',action.pid,'page'],action.page)
        // } else {
        //     return state.setIn(['categoryProductList',action.pid,'data'],action.data)
        // }

      case types.GET_SLIDE_IMAGE:
        return state.set('slideImage',action.data)

      case types.GET_SHOP_CONFIG:

        return state.set('shopConfig',action.data)
      
      case types.SET_PAYMENT_INFO:
        return state.set('paymentInfo',action.data)
      
      case types.SET_PAGE:
        // console.log(action)
        // var a  = state.setIn(['categoryProductList',action.id],action.page)
        // console.log(a.toJS())
        return state.setIn(['categoryProductList',action.id],action.page)

      default:
        return state;
    }
  };
  
  export default reducer;

// selectors

// 筛选出款式分类的数据
export const selectCategoryData = state => state.getIn(['home','categoryData'])

//根据款式id筛选该款式的所有商品
export const selectCategoryProductList = (state,id) => state.getIn(['home','categoryProductList',id,'data'])

//根据款式id筛选出该款式的总条数
export const selectCategoryProductTotal = (state,id) => state.getIn(['home','categoryProductList',id,'total'])

// 根据款式id筛选出该款式的当前查询的页数
export const selectCategoryProductPage = (state,id) => state.getIn(['home','categoryProductList',id,'page'])


// 筛选出首页的轮播图数据
export const selectSlideImage = state => state.getIn(['home','slideImage'])

// 筛选出商城配置信息
export const selectShopConfig = state => state.getIn(['home','shopConfig'])

//筛选出用户信息
export const selectUserInfo = state => state.getIn(['home','shopConfig','userInfo'])

//筛选出生成的订单数据
export const selectPaymentInfo = state => state.getIn(['home','paymentInfo'])
