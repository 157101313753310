import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class ShowAddressAreaItem extends Component {
    static propTypes = {
        name: PropTypes.string, //传递显示的地址
        clickFn: PropTypes.func, //点击地址执行的方法
    }
    render() {
        const {
            name,
            clickFn
        } = this.props
        return (
            <li 
                className={styles.liStyle} 
                onClick={clickFn}
            >{name}</li>    
        );
    }
}

export default ShowAddressAreaItem;