import React, { Component } from 'react';
import {
    LeftArea,
    CenterArea,
    RightArea
} from './components'
import styles from './style.module.less'

class SlideImage extends Component {
    render() {
        return (
            <div className={styles.body}>
                <div className={styles.center}>
                    <LeftArea />
                    <CenterArea />
                    <RightArea />
                </div>
            </div>
        );
    }
}

export default SlideImage;