import React, { Component } from 'react';
import styles from './style.module.less'
import PropTypes from 'prop-types'

class InfoTab extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired
    }
    render() {
        const {data} = this.props;
        // console.log(data)
        return (
            <div className={styles.infoTab}>
                <h2>{data.title}</h2>
                <ul>
                    {data.sub.map( item => 
                        <li key={item.title}>{item.title}</li>    
                    )}
                </ul>
            </div>
        );
    }
}

export default InfoTab;