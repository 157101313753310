import React, { Component } from 'react';
import styles from './style.module.less'
import { withRouter } from 'react-router-dom';


@withRouter
class MenuItem extends Component {
    render() {
        const {
            data,
            match:{path}
        } = this.props
        // console.log(this.props)
        return (
            <div className={styles.menuItem}>
                <div className={styles.title}>{data.title}</div>
                {
                    data.children.map( item => 
                        <div
                            className={styles.childItem}
                            key={item.title }
                            style={{
                                color: path === item.path ? 'rgb(44, 176, 163)' : '#666',
                                borderLeftColor: path === item.path ? 'rgb(44, 176, 163)' : 'white'
                            }}
                            onClick={this.goToPage.bind(this,item.path)}
                        >{item.title} </div>    
                    )
                }
            </div>
        );
    }

    //点击进入对应的分类页面
    goToPage = path => {
        const {
            history:{push}
        } = this.props
        push(path)
    }
}

export default MenuItem;