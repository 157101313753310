import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './style.module.less'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import {actions as homeActions} from '../../../../redux/modules/home'
@withRouter
@connect(null,homeActions)
class SubMenuItem extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired
    }
    render() {
        const {data} = this.props;
        return (
            <div className={styles.subMenuItem}>
                <ul>
                   {
                       data && data.map( item => 
                         <li 
                            key={item.text}
                            onClick={this.goToCategory.bind(this,item.id)}
                         >{item.text}</li>
                       )
                   } 
                </ul>
            </div>
        );
    }

    // 点击进入商品分类页
    goToCategory = id => {
        const {
            history:{push},
            getCategoryProductListRequest
        } = this.props
        //获取该款式分类的数据
        getCategoryProductListRequest({id,page:1,limit:10})
        // 进入款式分类界面
        push(`/category/${id}`)
    }
}

export default SubMenuItem;