import React, { Component } from 'react';
import styles from './style.module.less'
import ImageArea from './ImageArea'
import Pagination from './Pagination'
import {actions as homeActions,selectSlideImage} from '../../../../redux/modules/home'
import {connect} from 'react-redux'



const mapState = state => ({
    slideImage: selectSlideImage(state)
})
@connect(mapState,homeActions)
class CenterArea extends Component {
    state = {
        index: 0
    }
    componentDidMount() {
       const {
           slideImage,
           getSlideImageRequest
       } = this.props
       if(!slideImage) {
           getSlideImageRequest()
       }
        this.timer = setInterval(() => {
            const {
                index
            } = this.state
            const {
                slideImage
            } = this.props
            this.setIndex((index+1)% (slideImage.length))
        },2000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }
 
    render() {
        const {index} = this.state;
        const {
            slideImage
        } = this.props
        // console.log(slideImage)
        return (
            <div className={styles.body}>
                <div className={styles.innerDiv}>
                    {
                        slideImage
                        ?
                        <>
                            <ImageArea 
                                index={index} 
                                data={slideImage}
                            />
                            <Pagination 
                                index={index} 
                                setIndex={this.setIndex} 
                                data={slideImage}
                            />
                        </>
                        :
                        ''
                    }

                </div>
            </div>
        );
    }

    //设置选中的图片
    setIndex = index => {
        this.setState({
            index
        })
    }

}

export default CenterArea;